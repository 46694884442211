import { FieldArray, FormikErrors, FormikTouched } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { IParsedFormula } from "./UploadFormula";
import { InputWrapper } from "components/shared/inputWrapper";
import { IParsedRawIngredient } from "pages/rawIngredient/types";
import { SubIngredientItem } from "./SubIngredientItem";
import { ContaminantItem } from "./ContaminantItem";
import { DeleteIcon } from "assets/icons";
import BigNumber from "bignumber.js";
import { rawIngredientService } from "services";
import { AddSubIngredient } from "./AddSubIngredient";
import { AddContaminant } from "./AddContaminant";

interface IProps {
    index: number;
    errors: FormikErrors<IParsedFormula>;
    touched: FormikTouched<IParsedFormula>;
    item: IParsedRawIngredient;
    values: IParsedFormula;
    handleChange: (e: React.ChangeEvent<unknown>) => void;
    removeRawIngredient: <X = unknown>(index: number) => X | undefined;
    setFieldValue: (
        field: string,
        value: unknown,
        shouldValidate?: boolean | undefined,
    ) => Promise<void> | Promise<FormikErrors<IParsedFormula>>;
}

export const RawIngredientItem: FC<IProps> = ({
    index,
    errors,
    touched,
    values,
    item,
    handleChange,
    removeRawIngredient,
    setFieldValue,
}) => {
    const itemErrors = errors.rawIngredients?.[index] as
        | FormikErrors<IParsedRawIngredient>
        | undefined;
    const itemTouched = touched.rawIngredients?.[index] || {};
    const itemValues = values.rawIngredients?.[index];
    const [isOpenSubDialog, setIsOpenSubDialog] = useState(false);
    const [isOpenContaminantDialog, setIsOpenContaminantDialog] =
        useState(false);

    const handleToggleOpenSubDialog = () => {
        setIsOpenSubDialog(!isOpenSubDialog);
    };

    const handleToggleOpenContaminantDialog = () => {
        setIsOpenContaminantDialog(!isOpenContaminantDialog);
    };

    const totalSubWeight = useMemo(() => {
        return item.subIngredients.reduce((acc, ingredient) => {
            return new BigNumber(acc)
                .plus(ingredient.weight_percent)
                .toNumber();
        }, 0);
    }, [item]);

    useEffect(() => {
        const body = {
            ricode: itemValues.ricode,
            description: itemValues.description,
        };
        rawIngredientService
            .checkIsUserOwner(body)
            .then((res) => {
                setFieldValue(`rawIngredients.${index}.isOwner`, res.isOwner);
                setFieldValue(`rawIngredients.${index}.isExist`, res.isExist);
            })
            .catch(() => {
                setFieldValue(`rawIngredients.${index}.isOwner`, false);
                setFieldValue(`rawIngredients.${index}.isExist`, false);
            });
    }, [itemValues.ricode, itemValues.description]);

    const canModifyInner = useMemo(() => {
        return true;
        return (
            (itemValues.isExist && itemValues.isOwner) || !itemValues.isExist
        );
    }, [itemValues]);

    return (
        <div className="p-2 rounded-lg border">
            <div className="flex justify-between items-center">
                <span className="font-semibold ml-2 text-xl">{`#${
                    index + 1
                }`}</span>
                <button
                    onClick={() => {
                        removeRawIngredient(index);
                    }}
                    className="black-button !h-12"
                    type="button"
                >
                    Remove Raw Ingredient
                    <DeleteIcon width={18} height={18} />
                </button>
            </div>
            <div className="flex gap-2">
                {/* <InputWrapper
                    isError={Boolean(itemErrors?.ricode) && itemTouched?.ricode}
                    error={itemErrors?.ricode}
                    label="Ricode"
                    className="!w-1/4 !p-0"
                >
                    <input
                        type="text"
                        name={`rawIngredients.${index}.ricode`}
                        className="w-full input input-bordered"
                        value={itemValues.ricode}
                        onChange={handleChange}
                    />
                </InputWrapper> */}
                <InputWrapper
                    isError={
                        Boolean(itemErrors?.weight_percent) &&
                        itemTouched?.weight_percent
                    }
                    error={itemErrors?.weight_percent}
                    label="Weight percent"
                    className="!w-1/4 !p-0"
                >
                    <input
                        type="number"
                        name={`rawIngredients.${index}.weight_percent`}
                        className="w-full input input-bordered"
                        value={itemValues.weight_percent}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(
                        itemErrors?.description && itemTouched?.description,
                    )}
                    error={itemErrors?.description}
                    label="Description"
                    className="!p-0"
                >
                    <input
                        type="text"
                        name={`rawIngredients.${index}.description`}
                        className="w-full input input-bordered"
                        value={itemValues.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
            </div>

            {canModifyInner ? (
                <>
                    <div className="w-full my-4 font-bold">Included INCIs</div>
                    <FieldArray name={`rawIngredients.${index}.subIngredients`}>
                        {({ push, remove }) => (
                            <>
                                <div className="flex flex-col gap-2">
                                    {item.subIngredients.map((_, subIndex) => {
                                        const prefix = `rawIngredients.${index}`;

                                        return (
                                            <div key={`${index}+${subIndex}`}>
                                                {item.subIngredients.length >
                                                    1 && (
                                                    <span className="font-semibold ml-2">
                                                        #{subIndex + 1}
                                                    </span>
                                                )}
                                                <SubIngredientItem
                                                    errors={itemErrors}
                                                    touched={itemTouched}
                                                    values={itemValues}
                                                    prefixe={prefix}
                                                    index={subIndex}
                                                    handleChange={handleChange}
                                                    remove={remove}
                                                />
                                                {subIndex !==
                                                    item.subIngredients.length -
                                                        1 && (
                                                    <div className="w-full mt-5 h-[1px] bg-[#1f293733]" />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                {itemErrors &&
                                    typeof itemErrors === "string" && (
                                        <div className="text-[#dc2626]">
                                            {itemErrors}
                                        </div>
                                    )}
                                {isOpenSubDialog && (
                                    <AddSubIngredient
                                        push={push}
                                        onClose={handleToggleOpenSubDialog}
                                        totalSubWeight={totalSubWeight}
                                    />
                                )}
                                <button
                                    className="black-button mt-4 !font-bold text-lg"
                                    onClick={handleToggleOpenSubDialog}
                                    type="button"
                                    disabled={
                                        totalSubWeight >= 100 || isOpenSubDialog
                                    }
                                >
                                    Add INCI
                                </button>
                            </>
                        )}
                    </FieldArray>
                    <div className="w-full my-4 font-bold">
                        Included Contaminants
                    </div>
                    <FieldArray name={`rawIngredients.${index}.contaminants`}>
                        {({ push, remove }) => (
                            <>
                                <div className="flex flex-col gap-2">
                                    {item.contaminants.map(
                                        (_, contaminantIndex) => {
                                            const prefix = `rawIngredients.${index}`;

                                            return (
                                                <div
                                                    key={`${index}+${contaminantIndex}`}
                                                >
                                                    {item.contaminants.length >
                                                        1 && (
                                                        <span className="font-semibold ml-2">{`#${
                                                            contaminantIndex + 1
                                                        }`}</span>
                                                    )}
                                                    <ContaminantItem
                                                        errors={itemErrors}
                                                        touched={itemTouched}
                                                        values={itemValues}
                                                        prefixe={prefix}
                                                        index={contaminantIndex}
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        remove={remove}
                                                    />
                                                    {contaminantIndex !==
                                                        item.contaminants
                                                            .length -
                                                            1 && (
                                                        <div className="w-full mt-5 h-[1px] bg-[#1f293733]" />
                                                    )}
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                                {isOpenContaminantDialog && (
                                    <AddContaminant
                                        onClose={
                                            handleToggleOpenContaminantDialog
                                        }
                                        push={push}
                                    />
                                )}
                                <button
                                    className="black-button mt-4 !font-bold text-lg"
                                    onClick={handleToggleOpenContaminantDialog}
                                    type="button"
                                    disabled={isOpenContaminantDialog}
                                >
                                    Add Contaminant
                                </button>
                            </>
                        )}
                    </FieldArray>
                </>
            ) : (
                <h6 className="py-2 text-[#6a778a] text-base">
                    The requested Raw Ingredient or data belongs to another
                    user. Please ensure you have the necessary permissions to
                    access this information.
                </h6>
            )}
        </div>
    );
};
