import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useMemo,
    ChangeEvent,
} from "react";
import { ModalTemplate, Button } from "components/shared";
import { TemplateUploader, UploadFormulaForm } from "./components";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { type IParsedRawIngredient } from "pages/rawIngredient/types";
import { parseIngredients } from "./libs";
import { defaultErrorToastOptions } from "common/constants";
import { useModal } from "common/hooks";

export interface IParsedFormula {
    ficode: string;
    description: string;
    rawIngredients: IParsedRawIngredient[];
}

export const UploadFormula = () => {
    const [data, setData] = useState<Record<string, string>[] | null>(null);
    const [parsedFormula, setParsedFormula] = useState<IParsedFormula | null>(
        null,
    );
    const fileInputRef = useRef<HTMLInputElement>(null);

    const {
        modalRef: createFormulaRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            setData(null);
            setParsedFormula(null);
        },
    });

    const {
        modalRef: uploadFormulaRef,
        openModal: openModalUploadFormula,
        closeModal: closeModalUploadFormula,
    } = useModal({});

    const handleFileUpload = useCallback((file: File) => {
        try {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
                const arrayBuffer = event.target?.result as ArrayBuffer;
                if (!arrayBuffer) {
                    throw new Error("Failed to read file");
                }
                const data = new Uint8Array(arrayBuffer);
                const workbook = XLSX.read(data, { type: "array" });
                const sheetData = XLSX.utils.sheet_to_json(
                    workbook.Sheets[workbook.SheetNames[0]],
                ) as Record<string, string>[];
                setData(sheetData);
            };
            reader.readAsArrayBuffer(file);
        } catch (error) {
            toast.error("Failed to upload file", defaultErrorToastOptions);
        }
    }, []);

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (file) handleFileUpload(file);
        },
        [handleFileUpload],
    );

    const headerRowKeys = useMemo(() => {
        if (!data) return null;
        const headerRow = data.find((row) =>
            Object.values(row).includes("Ingredient Code"),
        );
        if (!headerRow) {
            toast.error("File format not recognized", defaultErrorToastOptions);
            return null;
        }
        return Object.fromEntries(
            Object.entries(headerRow).map(([key, value]) => [value, key]),
        );
    }, [data]);

    useEffect(() => {
        if (headerRowKeys && data) {
            const parsedIngredients = parseIngredients(data, headerRowKeys);
            setParsedFormula({
                ficode: "",
                description: "",
                rawIngredients: parsedIngredients,
            });
        }
    }, [headerRowKeys, data]);

    useEffect(() => {
        if (parsedFormula) openModal();
    }, [parsedFormula, openModal]);

    const handleClickUploadBatch = useCallback(() => {
        fileInputRef.current?.click();
        closeModalUploadFormula();
    }, [closeModalUploadFormula]);

    return (
        <div className="w-full">
            <ModalTemplate
                text="Create Formula"
                onClose={closeModal}
                modalRef={createFormulaRef}
                className="max-w-[1200px]"
            >
                {parsedFormula && (
                    <UploadFormulaForm
                        initialValues={parsedFormula}
                        onClose={closeModal}
                    />
                )}
            </ModalTemplate>
            <ModalTemplate
                text="Upload Formula"
                modalRef={uploadFormulaRef}
                onClose={closeModalUploadFormula}
                className="max-w-[500px]"
            >
                <TemplateUploader
                    onUploadClick={handleClickUploadBatch}
                    onCloseClick={closeModalUploadFormula}
                />
            </ModalTemplate>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleInputChange}
                accept=".xlsx, .xls, .csv"
            />
            <Button
                className="btn-upload h-14 px-10 py-1"
                onClick={openModalUploadFormula}
                text="Upload formula"
                isSplitText
            />
        </div>
    );
};
