import { FormikErrors, FormikTouched } from "formik";
import React, { FC } from "react";
import { InputWrapper } from "components/shared/inputWrapper";
import {
    IParsedRawIngredient,
    IParsedSubIngredient,
} from "pages/rawIngredient/types";
import { DeleteIcon } from "assets/icons";

interface IProps {
    index: number;
    prefixe: string;
    errors?: FormikErrors<IParsedRawIngredient>;
    touched: FormikTouched<IParsedRawIngredient>;
    values: IParsedRawIngredient;
    handleChange: (e: React.ChangeEvent<unknown>) => void;
    remove: <X = unknown>(index: number) => X | undefined;
}

export const SubIngredientItem: FC<IProps> = ({
    index,
    prefixe,
    errors,
    touched,
    values,
    handleChange,
    remove,
}) => {
    const itemErrors = errors?.subIngredients?.[index] as
        | FormikErrors<IParsedSubIngredient>
        | undefined;
    const itemTouched = touched.subIngredients?.[index] || {};
    const itemValues = values.subIngredients?.[index];

    return (
        <div className="flex space-x-2 mb-1">
            <InputWrapper
                isError={
                    Boolean(itemErrors?.weight_percent) &&
                    itemTouched?.weight_percent
                }
                error={itemErrors?.weight_percent}
                label="Weight percent"
                className="!w-1/4 !p-0"
            >
                <input
                    type="number"
                    name={`${prefixe}.subIngredients.${index}.weight_percent`}
                    className="w-full input input-bordered"
                    value={itemValues.weight_percent}
                    onChange={handleChange}
                />
            </InputWrapper>

            <InputWrapper
                isError={Boolean(itemErrors?.name) && itemTouched?.name}
                error={itemErrors?.name}
                label="Name"
                className="!p-0"
            >
                <input
                    type="text"
                    name={`${prefixe}.subIngredients.${index}.name`}
                    className="w-full input input-bordered"
                    value={itemValues.name}
                    onChange={handleChange}
                />
            </InputWrapper>
            <div className="flex justify-end items-end">
                <button
                    onClick={() => remove(index)}
                    className="black-button !h-12"
                    type="button"
                >
                    <DeleteIcon />
                </button>
            </div>
        </div>
    );
};
