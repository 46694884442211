import { IOption } from "common/types";
import { SearchableSelect } from "components/shared/SearchableSelect";
import { Button } from "components/shared/button";
import React, { FC } from "react";
import { MultiValue } from "react-select";

interface IProps {
    isLoading: boolean;
    options: MultiValue<IOption>;
    values: IOption | null;
    onChange: (newValue: IOption) => void;
    handleCreate: () => void;
    onClose: () => void;
    error: string | null;
}

export const AddNewItemTemplate: FC<IProps> = ({
    isLoading,
    options,
    values,
    onChange,
    handleCreate,
    onClose,
    error,
}) => (
    <div className="flex flex-col gap-2">
        <div className="flex justify-between items-end pb-2 gap-2">
            <div className="flex flex-col gap-2 w-2/3">
                <h6>Choose from list</h6>
                <SearchableSelect
                    isLoading={isLoading}
                    options={options}
                    values={values}
                    onChange={onChange}
                />
            </div>
            <div className="flex flex-col gap-2 w-1/3">
                <h6>Add</h6>
                <Button text="Add" onClick={handleCreate} />
            </div>
            <Button text="X" onClick={onClose} />
        </div>
        {error && <span className="text-[#dc2626]">{error}</span>}
    </div>
);
