import React, { FC, ChangeEvent } from "react";
import classNames from "classnames";
import { IOptionNumber, IOption } from "common/types";

interface IProps {
    name: string;
    options?: IOption[] | IOptionNumber[];
    value: string;
    defaultLabel?: string;
    defaultValue?: string;
    isDefaultOption?: boolean;
    onChange: (e: ChangeEvent<HTMLSelectElement>, filterValue?: string) => void;
    label?: string;
    isError?: boolean;
    error?: string;
    className?: string;
    helpText?: string;
    example?: string;
    disabled?: boolean;
    uniqueKey?: string;
}

export const DropdownWrapper: FC<IProps> = ({
    name,
    options = [],
    defaultLabel = "All",
    defaultValue = "",
    isDefaultOption = true,
    value,
    onChange,
    label = "",
    isError = false,
    error = "",
    helpText,
    example,
    disabled = false,
    uniqueKey = "",
    className = "",
}) => {
    const findOptionLabel = (selectedValue: string) => {
        const findValue = isNaN(Number(selectedValue))
            ? selectedValue
            : Number(selectedValue);

        for (const { value, label } of options) {
            if (value === findValue) {
                return label;
            }
        }

        return "";
    };

    const actualValue =
        !isDefaultOption && value === "" && options.length > 0
            ? options[0].value
            : value;

    return (
        <div className={className}>
            {label && (
                <label htmlFor={name} className="form-control w-full">
                    {(label || helpText) && (
                        <div className="label">
                            {label && (
                                <span className="text-base mr-1">{label}</span>
                            )}
                            {helpText && (
                                <span className="text-xs text-gray-500">
                                    {helpText}
                                </span>
                            )}
                        </div>
                    )}
                </label>
            )}
            <select
                className={classNames("select select-bordered w-full", {
                    "border-red-600": isError,
                })}
                name={name}
                value={actualValue}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    onChange(e, findOptionLabel(e.target.value))
                }
                disabled={disabled}
            >
                {isDefaultOption && (
                    <option key="default" value={defaultValue}>
                        {defaultLabel}
                    </option>
                )}
                {options.map((option, idx) => (
                    <option
                        key={`${name}-${uniqueKey}-${idx}`}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
            {isError && (
                <span className="label-text text-red-600 text-right absolute top-full right-2 first-letter:uppercase">
                    {error}
                </span>
            )}
            {example && (
                <span className="example mt-1 text-xs text-gray-500">
                    {example}
                </span>
            )}
        </div>
    );
};
