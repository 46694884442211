import classNames from "classnames";
import React, { ReactNode } from "react";

interface IProps {
    children: ReactNode;
    isError?: boolean;
    error?: string;
    label: string;
    className?: string;
    helpText?: string;
    example?: string;
}

export const InputWrapper: React.FC<IProps> = ({
    children,
    isError = false,
    error = "",
    label,
    className = "",
    helpText,
    example,
}) => (
    <label
        className={classNames("form-control relative w-full px-1", className)}
    >
        <div className="label">
            <span className="label-text">{label}</span>
            {helpText && (
                <span className="help-text text-xs text-gray-500">
                    {helpText}
                </span>
            )}
        </div>
        {children}
        {isError && (
            <span className="label-text text-red-600 text-right absolute top-full right-2 first-letter:uppercase pt-1">
                {error}
            </span>
        )}
        {example && (
            <span className="example mt-1 text-xs text-gray-500">
                {example}
            </span>
        )}
    </label>
);
