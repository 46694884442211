import React, {
    FC,
    ChangeEvent,
    KeyboardEvent,
    useRef,
    useEffect,
    useState,
} from "react";

interface IProps {
    name: string;
    value: string;
    isEditing: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    className?: string;
    inputClassName?: string;
    displayClassName?: string;
    autoFocus?: boolean;
}

export const EditableInput: FC<IProps> = ({
    name,
    value,
    isEditing,
    onChange,
    onKeyDown,
    className = "",
    inputClassName = "",
    displayClassName = "",
    autoFocus = false,
}) => {
    const [inputWidth, setInputWidth] = useState<number>(90);
    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanRef.current) {
            const span = spanRef.current;
            const computedWidth = span.offsetWidth;
            setInputWidth(computedWidth + 20);
        }
    }, [value]);

    return (
        <div className={className}>
            {isEditing ? (
                <>
                    <input
                        ref={inputRef}
                        name={name}
                        type="text"
                        value={value}
                        onChange={(e) => {
                            const updatedValue = e.target.value.replace(
                                ",",
                                ".",
                            );
                            onChange({
                                ...e,
                                target: {
                                    ...e.target,
                                    value: updatedValue,
                                },
                            });
                        }}
                        onKeyDown={onKeyDown}
                        style={{ width: `${inputWidth}px` }} // Dynamic width
                        className={`text-center px-1 rounded-lg border border-gray-300 bg-gray-50 text-gray-900 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition ease-in-out duration-200 ${inputClassName}`}
                        autoFocus={autoFocus}
                    />
                    <span
                        ref={spanRef}
                        style={{ visibility: "hidden", position: "absolute" }}
                    >
                        {value}
                    </span>
                </>
            ) : (
                <div
                    className={`text-center font-semibold text-gray-700 rounded-lg ${displayClassName}`}
                >
                    {value}
                </div>
            )}
        </div>
    );
};
