import React, { useMemo } from "react";
import { Title } from "components/shared/title";
import {
    useAddFormulaReport,
    useGenerateFormulaReport,
    useGetFormula,
    useGetFormulaReports,
} from "pages/formulas/formula-profile/queries";
import { ReportRow } from "./components";
import { ToastContainer, toast } from "react-toastify";
import { defaultErrorToastOptions, toastTexts } from "common/constants";
import { v4 as uuidv4 } from "uuid";
// import { LoadingToast } from "components/shared/loadingToast";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";

export const ReportTab = () => {
    const formula = useGetFormula();
    const { data: reportFiles, refetch } = useGetFormulaReports({
        ficode: formula?.ficode || "",
    });

    const { mutate: addFormulaReport } = useAddFormulaReport();
    const { mutate: generateReport } = useGenerateFormulaReport();
    const { isOwner } = useFormulaProfileState();

    const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        const fileSizeLimit = 10485760;

        if (!file) {
            return;
        }

        if (file.size > fileSizeLimit) {
            toast.error(
                `File size exceeds limit. Maximum allowed size is ${
                    fileSizeLimit / 1048576
                } MB`,
                defaultErrorToastOptions,
            );
            return;
        }

        if (file) {
            const formData = new FormData();
            formData.append("file", file);

            addFormulaReport(
                {
                    ficode: formula?.ficode || "",
                    body: formData,
                    filename: file.name,
                },
                {
                    onSuccess: () => {
                        refetch();
                    },
                    onError: () => {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        }
    };

    const handleGenerateReport = (type: string) => {
        generateReport(
            { id: String(formula?.id) || "", type },
            {
                onSuccess: () => {
                    refetch();
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    };

    const handleClick = (reportType: string, target: EventTarget) => {
        (target as HTMLElement)?.parentElement?.parentElement?.blur();
        handleGenerateReport(reportType);
    };

    const uploadLabel = useMemo(() => {
        return isOwner
            ? "Click here to add a file relevant to the cosmetic safety report (doc, docx, pdf, txt) up to 10 MB in size."
            : "File upload disabled";
    }, [isOwner]);

    return (
        <>
            <ToastContainer />
            <div>
                <div className="mx-10 my-3">
                    <div className="flex">
                        <Title text="Reports files" />
                    </div>
                    <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-3">
                        <tbody>
                            {reportFiles?.length ? (
                                <>
                                    {reportFiles.map((reportFile) => (
                                        <ReportRow
                                            key={uuidv4()}
                                            reportFile={reportFile}
                                        />
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td className="w-3/4 text-xs md:text-base lg:text-base p-3">
                                        no reports file found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {(isOwner || true) && (
                        <div className="flex">
                            <div className="dropdown mr-3">
                                <label tabIndex={0} className="btn">
                                    Generate report
                                </label>
                                <ul
                                    tabIndex={0}
                                    className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                                >
                                    <li>
                                        <a
                                            onClick={(e) =>
                                                handleClick("csar", e.target)
                                            }
                                        >
                                            CSAR
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={(e) =>
                                                handleClick("cpsr", e.target)
                                            }
                                        >
                                            CPSR
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-1/3 flex-col">
                                <input
                                    className="w-full file-input file-input-bordered"
                                    type="file"
                                    accept=".doc, .docx, .pdf, .txt"
                                    value=""
                                    onChange={handleAddFile}
                                />
                                <label className="label mt-1">
                                    <span>{uploadLabel}</span>
                                </label>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
