import { FormikErrors, FormikTouched } from "formik";
import React, { FC } from "react";
import { InputWrapper } from "components/shared/inputWrapper";
import {
    IParsedContaminant,
    IParsedRawIngredient,
} from "pages/rawIngredient/types";
import { DeleteIcon } from "assets/icons";

interface IProps {
    index: number;
    prefixe: string;
    errors?: FormikErrors<IParsedRawIngredient>;
    touched: FormikTouched<IParsedRawIngredient>;
    values: IParsedRawIngredient;
    handleChange: (e: React.ChangeEvent<unknown>) => void;
    remove: <X = unknown>(index: number) => X | undefined;
}

export const ContaminantItem: FC<IProps> = ({
    index,
    prefixe,
    errors,
    touched,
    values,
    handleChange,
    remove,
}) => {
    const itemErrors = errors?.contaminants?.[index] as
        | FormikErrors<IParsedContaminant>
        | undefined;
    const itemTouched = touched.contaminants?.[index] || {};
    const itemValues = values.contaminants?.[index];

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2">
                <InputWrapper
                    isError={Boolean(itemErrors?.name) && itemTouched?.name}
                    error={itemErrors?.name}
                    label="Name"
                    className="!w-1/4 !p-0"
                >
                    <input
                        name={`${prefixe}.contaminants.${index}.name`}
                        className="w-full input input-bordered"
                        value={itemValues.name}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(itemErrors?.ppm) && itemTouched?.ppm}
                    error={itemErrors?.ppm}
                    label="PPM"
                    className="!w-1/4 !p-0"
                >
                    <input
                        type="number"
                        name={`${prefixe}.contaminants.${index}.ppm`}
                        className="w-full input input-bordered"
                        value={itemValues.ppm}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={
                        Boolean(itemErrors?.description) &&
                        itemTouched?.description
                    }
                    error={itemErrors?.description}
                    label="Description"
                    className="!p-0"
                >
                    <input
                        name={`${prefixe}.contaminants.${index}.description`}
                        className="w-full input input-bordered"
                        value={itemValues.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex justify-end items-end">
                    <button
                        onClick={() => remove(index)}
                        className="black-button !h-12"
                        type="button"
                    >
                        <DeleteIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};
