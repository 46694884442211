import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import { IFDAClass, IProductTypes } from "common/types";

class FDAService extends BaseService {
    constructor(
        public baseUrl = backendKeys.fdaClasses,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async create(payload: Pick<IFDAClass, "code" | "name" | "created_by">) {
        return this.handleRequest<IFDAClass>(
            this.fetchingService.post(this.baseUrl, payload),
        );
    }

    async getAll() {
        const fdaClasses = await this.handleRequest<IFDAClass[]>(
            this.fetchingService.get(this.getFullUrl("/")),
        );

        return fdaClasses.map(({ id, code, name }) => ({
            key: `${id}`,
            value: `${code} ${name}`,
        }));
    }

    async removeFdaClass({ id }: { id: string }) {
        return this.handleRequest<void>(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }

    async getProductTypes({ fdaClassId }: { fdaClassId: string }) {
        const productTypes = fdaClassId
            ? await this.handleRequest<IProductTypes[]>(
                  this.fetchingService.get(
                      this.getFullUrl(`/product-types/${fdaClassId}`),
                  ),
              )
            : [];

        return productTypes.map(({ id, name }) => ({ key: id, value: name }));
    }

    async getAllProductTypes() {
        return this.handleRequest<IProductTypes[]>(
            this.fetchingService.get(this.getFullUrl("/product-types/")),
        );
    }

    async createProductType(
        payload: Pick<IProductTypes, "name" | "created_by">,
    ) {
        return this.handleRequest<IProductTypes>(
            this.fetchingService.post(
                this.getFullUrl(`/product-type/`),
                payload,
            ),
        );
    }

    async removeProductType(id: string) {
        return this.handleRequest<void>(
            this.fetchingService.delete(this.getFullUrl(`/product-type/${id}`)),
        );
    }
}
export const fdaService = new FDAService();
