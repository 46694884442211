import React, { FC, useRef } from "react";
import { useGetFormula } from "../queries";
import { EditIcon } from "assets/icons";
import { ModalTemplate } from "components/shared/modalTemplate";
import { EditFormulaForm } from "./EditFormulaForm";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { IFormula } from "../types";
import { useFormulaProfileState } from "../store";

interface IProps {
    refetch: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<IFormula, Error>>;
}

export const Header: FC<IProps> = ({ refetch }) => {
    const formula = useGetFormula();
    const { isOwner } = useFormulaProfileState();
    const createFormulaRef = useRef<HTMLDialogElement>(null);
    const openModal = () => {
        if (createFormulaRef.current) {
            createFormulaRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (createFormulaRef.current) {
            refetch();
            createFormulaRef.current.close();
        }
    };

    return (
        <>
            <ModalTemplate
                text="Edit formula"
                onClose={closeModal}
                modalRef={createFormulaRef}
                className="max-w-[600px]"
            >
                {formula && (
                    <EditFormulaForm
                        formula={formula}
                        handleClose={closeModal}
                    />
                )}
            </ModalTemplate>
            <div
                className="md:flex lg:flex p-3 mt-10 mx-10 justify-between rounded-lg"
                style={{ backgroundColor: "rgb(237 233 254)" }}
            >
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        {formula?.ficode}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        <b>{formula?.description}</b>
                    </span>
                </div>
                <div>
                    {(isOwner || true) && (
                        <button onClick={openModal}>
                            <EditIcon />
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
