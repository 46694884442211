import React, { useEffect, useMemo, useState } from "react";
import { PageTemplate } from "components/page-template";
import { Filters } from "./components/filters";
import {
    defaultErrorToastOptions,
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    formulaContent,
    routerKeys,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { useGetFormulas } from "./queries";
import { ToastContainer, toast } from "react-toastify";
import { ModalTemplate } from "components/shared/modalTemplate";
import { CreateFormulaForm } from "./components/createFormulaForm";
import { ButtonBlock } from "components/shared/button-block/ButtonBlock";
import {
    DataTable,
    FilterBlock,
    IconLink,
    SaveButton,
} from "components/shared";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { ColumnDef } from "@tanstack/react-table";
import { IFormulaItem } from "./types";
import { useFormik } from "formik";
import {
    useSaveFormulaMutation,
    useGetUserProfile,
    useGetSavedFormulas,
    useFullSavedFormulas,
} from "pages/profile/libs";
import { formatLabel, modifyDate } from "common/utils";

export const Formulas = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const {
        data: formulasData,
        isLoading,
        isError,
        refetch,
    } = useGetFormulas({
        queryParams: getParamsString(),
        sorting,
    });

    useEffect(() => {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        }
    }, [isError]);

    const {
        modalRef: createFormulaRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    const {
        handleUpdateFilters,
        handleUpdateSearch,
        handleFilterVisible,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const { mutate: saveFormula } = useSaveFormulaMutation();
    const { data: userInfo } = useGetUserProfile();
    const { data: savedFormulas, refetch: refetchSavedFormulas } =
        useGetSavedFormulas();
    const { refetch: refetchFullFormulas } = useFullSavedFormulas();

    const formik = useFormik({
        initialValues: {
            userId: userInfo?.id || "",
            formulaId: 0,
        },

        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            saveFormula(values, {
                onSuccess: () => {
                    toast.update(toastId, {
                        ...defaultSuccessToastUpdate,
                        render: toastTexts.success,
                    });

                    if (refetchFullFormulas) {
                        refetchFullFormulas();
                    }

                    if (refetchSavedFormulas) {
                        refetchSavedFormulas();
                    }
                },
                onError: () => {
                    toast.update(toastId, defaultErrorToastUpdate);
                },
            });
        },
    });

    const handleSaveFormula = (formulaId: number) => {
        formik.setValues({ userId: userInfo?.id || "", formulaId });
        formik.submitForm();
        refetchFullFormulas();
        refetchSavedFormulas();
    };

    const columns = useMemo<ColumnDef<IFormulaItem>[]>(
        () => [
            {
                accessorKey: "ficode",
                header: () => <span className="max-w-[100px]">FI Code</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    >
                        {row.getValue("ficode")}
                    </IconLink>
                ),
            },
            {
                accessorKey: "description",
                header: () => <span>Description</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    >
                        <span className="max-w-[100px]">
                            {row.getValue("description")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <span>{formatLabel(row.getValue("status"))}</span>
                ),
            },
            {
                accessorKey: "created_by",
                header: () => <span>Created By</span>,
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "unsaved",
                accessorKey: "unsaved",
                header: () => <span className="flex justify-end">Save</span>,
                cell: ({ row }) => (
                    <SaveButton
                        className="flex justify-end hover:cursor-pointer"
                        id={row.original.id}
                        onAction={handleSaveFormula}
                        items={savedFormulas || []}
                        attribute="formulaId"
                        isDisabled
                    />
                ),
            },
            {
                id: "action",
                accessorKey: "action",
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [savedFormulas, savedFormulas?.length, handleSaveFormula],
    );

    return (
        <PageTemplate
            title={formulaContent.title}
            subtitle={formulaContent.subTitle}
            searchValue={filters?.query?.value}
            searchPlaceholder={formulaContent.placeholder}
            onChange={handleUpdateSearch}
            buttonBlock={
                <ButtonBlock
                    title={formulaContent.title}
                    createFn={openModal}
                    isUpload
                />
            }
        >
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create Formula"
                    onClose={closeModal}
                    modalRef={createFormulaRef}
                    className="max-w-[600px]"
                >
                    <CreateFormulaForm handleClose={closeModal} />
                </ModalTemplate>

                <div className="w-full mx-10">
                    {/* Filter button and filters status */}
                    <FilterBlock
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        onRemoveFilter={handleRemoveFilter}
                        filters={filters}
                    />

                    <DataTable
                        data={formulasData?.rows || []}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={formulasData?.count || 0}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        {/* Custom filters */}
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </DataTable>

                    {isLoading ? <LoadingToast /> : <ToastContainer />}
                </div>
            </div>
        </PageTemplate>
    );
};
