import React, { FC, useRef } from "react";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useGetSubIngredient } from "../queries";
import { EditIcon } from "assets/icons";
import { useSubIngredientStore } from "../store";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { ISubIngredientResponse } from "../types";
import { UpdateSubIngredientForm } from "pages/subIngredient/subIngredients/components/UpdateSubIngredientForm";

interface IProps {
    refetch: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<ISubIngredientResponse, Error>>;
}

export const Header: FC<IProps> = ({ refetch }) => {
    const subIngredient = useGetSubIngredient();
    const { isOwner } = useSubIngredientStore();

    const updateSubIngredientRef = useRef<HTMLDialogElement>(null);

    const openModal = () => {
        if (updateSubIngredientRef.current) {
            updateSubIngredientRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (updateSubIngredientRef.current) {
            refetch();
            updateSubIngredientRef.current.close();
        }
    };

    return (
        <>
            <ModalTemplate
                text="Edit INCI"
                onClose={closeModal}
                modalRef={updateSubIngredientRef}
                className="max-w-[600px]"
            >
                {subIngredient && (
                    <UpdateSubIngredientForm
                        handleClose={closeModal}
                        subIngredient={subIngredient}
                    />
                )}
            </ModalTemplate>
            <div
                className="md:flex lg:flex p-3 mt-10 mx-10 justify-between rounded-lg"
                style={{ backgroundColor: "rgb(237 233 254)" }}
            >
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        {subIngredient?.sicode}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        <b>{subIngredient?.name}</b>
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        {subIngredient?.class}
                        {(isOwner || true) && (
                            <button className="ml-2" onClick={openModal}>
                                <EditIcon />
                            </button>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
};
