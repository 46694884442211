// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.react-select__control {
    min-height: 48px;
    border-radius: var(--rounded-btn, 0.5rem);
}

div.react-select__control:hover {
    border-color: rgb(73 86 127 / var(--tw-border-opacity)) !important;
}

div.react-select__control:active {
    border-color: #5f2eea !important;
}

div.react-select__control.react-select__control--is-focused {
    box-shadow: none;
    border-color: #5f2eea !important;
    outline-style: solid !important;
    outline-width: 2px !important;
    outline-offset: 2px !important;
    outline-color: var(--fallback-bc, oklch(var(--bc) / 0.2)) !important;
}

.react-select__menu {
  overflow-x: hidden;
}

.react-select__multi-value__remove {
  background-color: transparent !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/SearchableSelect/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yCAAyC;AAC7C;;AAEA;IACI,kEAAkE;AACtE;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,gCAAgC;IAChC,+BAA+B;IAC/B,6BAA6B;IAC7B,8BAA8B;IAC9B,oEAAoE;AACxE;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":["div.react-select__control {\n    min-height: 48px;\n    border-radius: var(--rounded-btn, 0.5rem);\n}\n\ndiv.react-select__control:hover {\n    border-color: rgb(73 86 127 / var(--tw-border-opacity)) !important;\n}\n\ndiv.react-select__control:active {\n    border-color: #5f2eea !important;\n}\n\ndiv.react-select__control.react-select__control--is-focused {\n    box-shadow: none;\n    border-color: #5f2eea !important;\n    outline-style: solid !important;\n    outline-width: 2px !important;\n    outline-offset: 2px !important;\n    outline-color: var(--fallback-bc, oklch(var(--bc) / 0.2)) !important;\n}\n\n.react-select__menu {\n  overflow-x: hidden;\n}\n\n.react-select__multi-value__remove {\n  background-color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
