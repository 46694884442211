export const parseIngredients = (
    data: Record<string, string>[],
    headerRowKeys: Record<string, string>,
) => {
    const startIdx = data.findIndex((row) =>
        Object.values(row).includes("Ingredient Code"),
    );
    const keys = {
        ricode: headerRowKeys["Ingredient Code"],
        rawDescription: headerRowKeys["Ingredient Description"],
        rawWeight: headerRowKeys["% Raw Ingredient"],
        contaminant: headerRowKeys["Contaminant"],
        subDescription: headerRowKeys["INCI Name"],
        subWeight: headerRowKeys["% Sub-Ingredient"],
    };
    const rawIngredients = [];

    for (const item of data.slice(startIdx + 1)) {
        const contaminant = item[keys.contaminant]
            ? { name: item[keys.contaminant], description: "", ppm: "" }
            : null;
        const sabWeight = isNaN(+item[keys.subWeight])
            ? item[keys.subWeight]?.split(" ")[0] || "0"
            : item[keys.subWeight];
        if (item[keys.ricode]) {
            const rawWeight = isNaN(+item[keys.rawWeight])
                ? item[keys.rawWeight]?.split(" ")[0] || "0"
                : item[keys.rawWeight];
            const rawIngredient = {
                ricode: "",
                description: item[keys.rawDescription],
                weight_percent: rawWeight,
                isOwner: false,
                isExist: false,
                contaminants: contaminant ? [contaminant] : [],
                subIngredients: [
                    {
                        name: item[keys.subDescription],
                        weight_percent: sabWeight,
                    },
                ],
            };
            rawIngredients.push(rawIngredient);
        } else {
            const lastRawIngredient = rawIngredients[rawIngredients.length - 1];
            if (contaminant) lastRawIngredient.contaminants.push(contaminant);
            if (item[keys.subDescription] || item[keys.subWeight]) {
                lastRawIngredient.subIngredients.push({
                    name: item[keys.subDescription],
                    weight_percent: sabWeight,
                });
            }
        }
    }

    return rawIngredients;
};
