import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import "../styles.scss";

interface IProps {
    to: string;
    text: string;
    image?: string;
    className?: string;
}

export const DesktopLink: React.FC<IProps> = ({
    to,
    text,
    image,
    className = "w-52",
}) => (
    <NavLink
        to={to}
        className={({ isActive }) =>
            classNames("nav-link", className, {
                "active-item": isActive,
            })
        }
    >
        {image && <img src={image} className="h-6 w-6" alt="" />}
        {text}
    </NavLink>
);
