import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    useLocation,
    useParams,
    useSearchParams,
    useNavigate,
} from "react-router-dom";
import { useRemoveSubIngredient, useUploadSubIngredient } from "./queries";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
    routerKeys,
    queryKeys,
} from "common/constants";
import { Header } from "./components";
import { TabsBox } from "components/tabsBox";
import { SubIngredientTab } from "./types";
import { TabContent } from "./components/tabs/TabContent";
import { ToastContainer, toast } from "react-toastify";
import { LoadingToast } from "components/shared/loadingToast";
import { useSubIngredientStore } from "./store";
import { useQueryClient } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";

const tabs: SubIngredientTab[] = [
    "Basic Information",
    "Safety Summary",
    "Report Summary",
    "Environmental Summary",
];

export const SubIngredientProfile = () => {
    const { id = "" } = useParams();
    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(search);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { keycloak } = useKeycloak();

    const [selectedTab, setSelectedTab] = useState(
        (searchParams.get("tab") as SubIngredientTab) || tabs[0],
    );

    const onSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab as SubIngredientTab);
            searchParams.set("tab", tab);
            setSearchParams(searchParams);
        },
        [setSelectedTab],
    );

    const { setIsOwner, isOwner, setKey } = useSubIngredientStore();
    const {
        data: subIngredient,
        isLoading,
        isError,
        refetch,
    } = useUploadSubIngredient(id);

    useEffect(() => {
        if (id) {
            const queryKey = [queryKeys.subIngredients, id];
            setKey(queryKey);
        }
    }, [id]);

    const isUserOwner = useMemo(() => {
        return keycloak.tokenParsed?.email === subIngredient?.created_by;
    }, [keycloak.tokenParsed?.email, subIngredient?.created_by]);

    useEffect(() => {
        // setIsOwner(isUserOwner);
        setIsOwner(true);

        return () => {
            setIsOwner(false);
        };
    }, [isUserOwner, setIsOwner]);

    const { mutate } = useRemoveSubIngredient();

    const removeSubIngredient = useCallback(() => {
        mutate(
            { id },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.subIngredients],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.subIngredients],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.subIngredientsEnvironmentalClass],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.subIngredientsOverallRank],
                    });
                    navigate(routerKeys.subIngredient);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [id]);

    const onRemove = useCallback(() => {
        if (isOwner) {
            return removeSubIngredient;
        }
    }, [removeSubIngredient, isOwner]);

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!subIngredient || isError) {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        } else {
            toast.info(toastTexts.dataLack, defaultToastOptions);
        }

        return <ToastContainer />;
    }

    return (
        <>
            <Header refetch={refetch} />
            <TabsBox
                tabs={tabs}
                onSelectTab={onSelectTab}
                selectedTab={selectedTab}
                onRemove={onRemove()}
            />
            <TabContent selectedTab={selectedTab} />
        </>
    );
};
