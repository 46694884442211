import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import React, { useMemo } from "react";
import { TabTable } from "../../../TabTable";
import { useGetRawIngredient } from "pages/rawIngredient/rawIngredientProfile/queries";
import { SubIngredientRow } from "./SubIngredientRow";
import { TableCeil } from "components/shared/tableCeil";

export const CompositionTable = () => {
    const rawIngredient = useGetRawIngredient();
    const { subIngredients = [] } = rawIngredient || {};

    const totalAmount =
        useMemo(
            () =>
                subIngredients?.reduce((acc, el) => {
                    const value =
                        +el.rawIngredientsSubIngredients.weight_percent;
                    return acc + value;
                }, 0),
            [rawIngredient],
        ) || 0;

    return (
        <TabTable>
            <thead className="">
                <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                    <TableHeaderCeil text="SI Code" />
                    <TableHeaderCeil text="INCI" />
                    <TableHeaderCeil
                        text="% w/w"
                        className="w-auto whitespace-nowrap"
                    />
                    <TableHeaderCeil text="CAS #" />
                    <TableHeaderCeil className="w-2" />
                </tr>
            </thead>
            <tbody className="sm:flex-none">
                {subIngredients?.length ? (
                    <>
                        {subIngredients
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((subIngredient) => (
                                <SubIngredientRow
                                    key={`sub-${subIngredient.id}`}
                                    subIngredient={subIngredient}
                                    totalAmount={totalAmount}
                                />
                            ))}
                        <tr>
                            <TableCeil />
                            <TableCeil
                                className="font-bold text-right"
                                text={`Total amount:`}
                            />
                            <TableCeil
                                className="text-center"
                                text={`${totalAmount}`}
                            />
                            <TableCeil />
                            <TableCeil />
                        </tr>
                    </>
                ) : (
                    <tr>
                        <TableCeil text="No Sub Ingredients found" />
                        {[...Array(4)].map((_, idx) => (
                            <TableCeil key={idx} />
                        ))}
                    </tr>
                )}
            </tbody>
        </TabTable>
    );
};
