import React, { FC, ReactNode, memo } from "react";
import addCircle from "assets/images/icons/add-circle.svg";
import minusCircle from "assets/images/icons/minus-cirlce.svg";

interface IProps {
    title?: string;
    children?: ReactNode;
    isVisibleMoreButton?: boolean;
    handleIncreaseLimit?: () => void;
    handleDecreaseLimit?: () => void;
}

export const TableInfo: FC<IProps> = memo(
    ({
        title = "",
        children,
        isVisibleMoreButton = false,
        handleIncreaseLimit,
        handleDecreaseLimit,
    }) => (
        <>
            <h3 className="text-lg font-normal uppercase mx-10 mb-4">
                {title}
            </h3>

            <div className="flex justify-end mr-4">
                {isVisibleMoreButton && (
                    <button
                        className="flex flex-col items-center text-blue-950"
                        onClick={handleDecreaseLimit}
                    >
                        <img
                            className="w-6 h-6"
                            src={minusCircle}
                            alt="arrow down icon"
                        />
                    </button>
                )}
            </div>

            {children}
            <div className="text-secondary-info justify-center font-bold p-4 flex md:flex">
                {isVisibleMoreButton && (
                    <button
                        className="flex flex-col items-center text-blue-950"
                        onClick={handleIncreaseLimit}
                    >
                        <img
                            className="w-6 h-6"
                            src={addCircle}
                            alt="arrow down icon"
                        />
                    </button>
                )}
            </div>
        </>
    ),
);
