import React, { ChangeEvent, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Title } from "components/shared";
import { LeftTable, RightTable } from "./components";
import { TabContentTemplate } from "../../TabContentTemplate";
import { useUpdateSubIngredient } from "pages/subIngredient/subIngredientProfile/queries";
import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";
import { useSubIngredientData } from "pages/subIngredient/subIngredientProfile/hooks";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
} from "common/constants";

export const BasicInfoTab = () => {
    const { key, isOwner } = useSubIngredientStore();
    const { mutate, isPending } = useUpdateSubIngredient();
    const queryClient = useQueryClient();

    const { data, setData, subIngredient } = useSubIngredientData();

    const handleChangeData = useCallback(
        (
            e: ChangeEvent<
                HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
            >,
        ) => {
            const { name, value } = e.target;

            setData((curr) => ({
                ...curr,
                [name]: value,
            }));
        },
        [],
    );

    const handleChangeMultiSelect = useCallback(
        (selected: string[], name: string) => {
            setData((curr) => ({
                ...curr,
                [name]: selected.join(","),
            }));
        },
        [],
    );

    const onSubmit = useCallback(() => {
        mutate(
            { id: `${subIngredient?.id}` || "", body: data },
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(key, data);
                    toast.success(
                        `${toastTexts.success} INCI has been updated.`,
                        defaultToastOptions,
                    );
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [data, subIngredient?.id]);

    return (
        <>
            <ToastContainer />
            <TabContentTemplate>
                <Title text="Basic Information" />
                <div className="md:flex">
                    <div className="w-full flex">
                        <LeftTable
                            data={data}
                            onChangeData={handleChangeData}
                            onChangeMultiSelect={handleChangeMultiSelect}
                        />

                        <RightTable
                            data={data}
                            onChangeData={handleChangeData}
                        />
                    </div>
                </div>

                <Button
                    isOwner={isOwner}
                    classNameContainer="md:flex lg:pb-5 mt-2"
                    isLoading={isPending}
                    onClick={onSubmit}
                    text="Save basic information"
                />
            </TabContentTemplate>
        </>
    );
};
