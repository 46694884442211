import React from "react";

export const Footer = () => (
    <footer className="bg-footer h-18 flex justify-center items-center">
        <div className="flex justify-center items-center">
            <p className="text-gray-500 text-sm text-center">
                an <span className="primary-color">Edelweiss</span>{" "}
                <span className="primary-color font-bold">Connect</span> product
            </p>
        </div>
    </footer>
);
