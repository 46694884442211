export const fdaClasses = [
    {
        fda_product_classification: " ",
    },
    {
        fda_product_classification: "02B Bubble Bath",
    },
    {
        fda_product_classification: "03A Eyebrow Pencil",
    },
    {
        fda_product_classification: "03C Eye Shadow",
    },
    {
        fda_product_classification: "03F Mascara",
    },
    {
        fda_product_classification: "04A Cologne and Toilet Waters",
    },
    {
        fda_product_classification: "04B Perfumes",
    },
    {
        fda_product_classification: "04E Other Fragrance Preparations",
    },
    {
        fda_product_classification: "05G Tonics, Dressings,Other HairGrm Aids",
    },
    {
        fda_product_classification: "07A Blushers (all types)",
    },
    {
        fda_product_classification: "07B Face Powders",
    },
    {
        fda_product_classification: "07C Foundations",
    },
    {
        fda_product_classification: "07E Lipstick",
    },
    {
        fda_product_classification: "08E Nail Polish and Enamel",
    },
    {
        fda_product_classification: "10B Deodorants (underarm)",
    },
    {
        fda_product_classification: "11A Aftershave Lotion",
    },
    {
        fda_product_classification: "12C Face/Neck (excluding shaving prep.)",
    },
    {
        fda_product_classification: "12D Body/Hand (excluding shaving prep.)",
    },
    {
        fda_product_classification: "12F Moisturizing",
    },
    {
        fda_product_classification: "13C Other Suntan Preparations",
    },
    {
        fda_product_classification: "14A US Drug Products",
    },
    {
        fda_product_classification: "14B Japan Quasi-Drugs",
    },
    {
        fda_product_classification: "15A Bath Soaps",
    },
    {
        fda_product_classification: "16A Candles and Pomanders",
    },
    {
        fda_product_classification: "17A Sachets and Room Fresheners",
    },
    {
        fda_product_classification: "99Z Miscellaneous Gift/Dec",
    },
    {
        fda_product_classification: "Other",
    },
];
