import { Button } from "components/shared/button";
import { ModalTemplate } from "components/shared/modalTemplate";
import React, { FC, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { CreateINCIForm } from "pages/subIngredient/subIngredients/components";
import { CreateContaminantForm } from "pages/contaminant/contaminants/components";
import { useGetRawIngredient, useUploadRawIngredient } from "../queries";

type tabType = "INCI" | "Contaminant";

interface IProps {
    type: tabType;
}

export const AddRelatedIngredients: FC<IProps> = ({ type }) => {
    const createIngredientsRef = useRef<HTMLDialogElement>(null);
    const { id = "" } = useParams();
    const { refetch } = useUploadRawIngredient({ id });
    const rawIngredient = useGetRawIngredient();

    const totalAmount =
        useMemo(
            () =>
                rawIngredient?.subIngredients?.reduce((acc, el) => {
                    const value =
                        +el.rawIngredientsSubIngredients.weight_percent;
                    return acc + value;
                }, 0),
            [rawIngredient],
        ) || 0;

    const openModal = () => {
        if (createIngredientsRef.current) {
            createIngredientsRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (createIngredientsRef.current) {
            createIngredientsRef.current.close();
            refetch();
        }
    };

    const ContentMap = {
        INCI: (
            <CreateINCIForm
                handleClose={closeModal}
                raw_ingredients_id={id}
                maxValue={100 - totalAmount}
            />
        ),
        Contaminant: (
            <CreateContaminantForm
                handleClose={closeModal}
                raw_ingredients_id={id}
            />
        ),
    };

    return (
        <div className="w-full flex my-4">
            <Button text={`Add ${type}`} onClick={openModal} />
            <ModalTemplate
                text={`Add ${type}`}
                onClose={closeModal}
                modalRef={createIngredientsRef}
                className="max-w-[600px]"
            >
                {ContentMap[type]}
            </ModalTemplate>
        </div>
    );
};
