import React, { ChangeEvent, FC, useCallback } from "react";
import search from "assets/images/icons/search-normal-1.svg";

interface IProps {
    placeholder?: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const defaultPlaceholder =
    "Search formula, raw ingredient, INCI or contaminant";

export const SearchBlock: FC<IProps> = ({
    placeholder = defaultPlaceholder,
    value,
    onChange,
}) => {
    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                e.preventDefault();
            }
        },
        [],
    );

    return (
        <div className="w-full my-6">
            <form>
                <div className="relative w-full h-16">
                    <img
                        src={search}
                        alt="Search Icon"
                        className="absolute left-3 top-1/2 transform -translate-y-1/2"
                    />
                    <input
                        type="text"
                        className="text-lg subtitle-sm w-full h-16 pl-10 pt-0.5 border-0 focus:border-0 rounded-md sm:shadow-lg"
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </form>
        </div>
    );
};
