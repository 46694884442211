import React, { FC, memo } from "react";
import {
    defaultErrorToastUpdate,
    // defaultErrorToastOptions,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { InputWrapper } from "components/shared/inputWrapper";
import * as Yup from "yup";
import { Button } from "components/shared/button";
import { useCreateProductType } from "pages/formulas/formula-profile/queries";
import { useQueryClient } from "@tanstack/react-query";
import { IError } from "common/types";
import { useKeycloak } from "@react-keycloak/web";

interface IProps {
    // fdaClassId: string;
    onClose: () => void;
    updateProductType: (name: string, productTypeId: string) => void;
}

const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Product type is required"),
});

export const AddProductTypeForm: FC<IProps> = memo(
    ({ onClose, updateProductType }) => {
        const queryClient = useQueryClient();
        const { keycloak } = useKeycloak();
        const { mutate } = useCreateProductType();

        const formik = useFormik({
            initialValues: {
                name: "",
            },
            validationSchema: CreateSchema,
            validateOnChange: true,
            validateOnMount: true,
            enableReinitialize: true,
            onSubmit: (values) => {
                // if (!Boolean(fdaClassId)) {
                //     toast.error(
                //         "FDA class is required",
                //         defaultErrorToastOptions,
                //     );
                //     return;
                // }

                const toastId = toast.loading(
                    toastTexts.loading,
                    defaultToastOptions,
                );

                mutate(
                    {
                        ...values,
                        // fdaClassId,
                        created_by: keycloak?.tokenParsed?.email,
                    },
                    {
                        onSuccess: (data) => {
                            toast.update(toastId, {
                                ...defaultSuccessToastUpdate,
                                render: "Success! Product type has been added.",
                            });

                            updateProductType("product_type", `${data.id}`);
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.productTypes],
                            });

                            formik.resetForm();
                            onClose();
                        },
                        onError: (err: IError) => {
                            const message = err?.response?.data?.message || "";

                            if (message.includes("name")) {
                                formik.setFieldError("name", message);
                            } else {
                                toast.update(toastId, defaultErrorToastUpdate);
                            }
                        },
                    },
                );
            },
        });

        const { values, errors, touched, handleChange, resetForm } = formik;

        const handleResetForm = () => {
            resetForm();
        };

        return (
            <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-4">
                    <InputWrapper
                        isError={Boolean(errors.name && touched.name)}
                        error={errors.name}
                        label="Product type"
                    >
                        <input
                            type="text"
                            name="name"
                            className="w-full input input-bordered"
                            value={values.name}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        />
                    </InputWrapper>
                    <div className="flex items-center justify-end gap-2 mt-3">
                        <Button text="Clear" onClick={handleResetForm} />
                        <Button text="Create" type="submit" />
                    </div>
                </div>
            </form>
        );
    },
);
