import React, {
    useCallback,
    useState,
    useRef,
    ChangeEvent,
    useEffect,
} from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import {
    DropdownWrapper,
    CheckboxLabel,
    Button,
    TableCeil,
    Title,
    ModalTemplate,
} from "components/shared";
import {
    Input,
    TableRow,
    AddFdaClassForm,
    AddProductTypeForm,
} from "./components";
import { IProperties } from "./types";
import {
    useGetFormula,
    useUpdateFormula,
    useGetAllProductTypes,
    useFormulaStatusOptions,
} from "pages/formulas/formula-profile/queries";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { getInitialFormulaObject } from "./helpers";
import { rinseOptions } from "common/data";

export const PropertyTab = () => {
    const formula = useGetFormula();
    const { isOwner } = useFormulaProfileState();
    const addFdaClassRef = useRef<HTMLDialogElement>(null);
    const productTypeRef = useRef<HTMLDialogElement>(null);
    const [values, setValues] = useState<IProperties>(
        getInitialFormulaObject(formula),
    );
    const { data: formulaStatusOptions = [] } = useFormulaStatusOptions();

    useEffect(() => {
        setValues((curr) => ({
            ...curr,
            ...getInitialFormulaObject(formula),
            status: {
                value:
                    curr.status.value || formulaStatusOptions?.[0]?.value || "",
            },
        }));
    }, [formula, formulaStatusOptions]);

    const handleChangeValue = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = e.target;

        if (value === "other" && name === "fda_class") {
            handleOpenFdaClassModal();
        } else if (value === "other" && name === "product_type") {
            handleOpenProductTypeModal();
        } else {
            setValues((curr) => ({
                ...curr,
                [name]: { value },
            }));
        }
    };

    const setValue = (name: string, id: string) => {
        setValues((curr) => ({
            ...curr,
            [name]: {
                value: id,
            },
        }));
    };

    const { data: productTypesData = [] } = useGetAllProductTypes();

    const { key, formulaeExtraInfo } = useFormulaProfileState();
    const queryClient = useQueryClient();

    const { mutate, isPending } = useUpdateFormula();

    const handleUpdateFormula = () => {
        mutate(
            {
                id: `${formula?.id}`,
                body: Object.entries(values).reduce(
                    (acc, [key, value]) =>
                        key === "fda_class"
                            ? {
                                  ...acc,
                                  fda_product_classification: value.value,
                              }
                            : { ...acc, [key]: value.value },
                    {},
                ),
            },
            {
                onSuccess: (data) => {
                    toast.success(
                        `${toastTexts.success} Formula has been updated.`,
                        defaultToastOptions,
                    );

                    queryClient.setQueryData(key, data);
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.productClassification],
                    });
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    };

    const handleOpenFdaClassModal = useCallback(() => {
        addFdaClassRef.current?.showModal();
    }, []);

    const handleCloseFdaClassModal = useCallback(() => {
        addFdaClassRef.current?.close();
        queryClient.refetchQueries({
            queryKey: [queryKeys.fdaClasses],
        });
    }, [queryClient]);

    const handleOpenProductTypeModal = useCallback(() => {
        productTypeRef.current?.showModal();
    }, []);

    const handleCloseProductTypeModal = useCallback(() => {
        productTypeRef.current?.close();
        queryClient.refetchQueries({
            queryKey: [queryKeys.fdaClasses],
        });
    }, [queryClient]);

    return (
        <div data-tab="general-tabs-prop">
            <div className="md:mx-5 lg:mx-10 mt-3">
                <Title text="Formula Properties" />
                <ModalTemplate
                    text="Add FDA Classification"
                    onClose={handleCloseFdaClassModal}
                    modalRef={addFdaClassRef}
                    className="max-w-[600px]"
                >
                    <AddFdaClassForm
                        onClose={handleCloseFdaClassModal}
                        updateFdaClass={setValue}
                    />
                </ModalTemplate>
                <ModalTemplate
                    text="Add Product Type"
                    onClose={handleCloseProductTypeModal}
                    modalRef={productTypeRef}
                    className="max-w-[600px]"
                >
                    <AddProductTypeForm
                        onClose={handleCloseProductTypeModal}
                        updateProductType={setValue}
                    />
                </ModalTemplate>
                <form className="w-full flex">
                    <div className="md:w-1/2 lg:w-1/2 md:mr-5">
                        <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                            <tbody>
                                {formula?.projects.map((project) => (
                                    <TableRow key={project.id}>
                                        <TableCeil
                                            className="w-1/3"
                                            text="Project Type / Code"
                                        />
                                        <TableCeil
                                            className="w-2/3"
                                            text={`${project.type} / ${project.code}`}
                                        />
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCeil text="Rinse Off / Leave On" />
                                    <TableCeil>
                                        <DropdownWrapper
                                            name="rinseoff_leaveon"
                                            defaultLabel=""
                                            value={
                                                values?.rinseoff_leaveon?.value
                                            }
                                            onChange={handleChangeValue}
                                            options={rinseOptions}
                                        />
                                    </TableCeil>
                                </TableRow>
                                <TableRow>
                                    <TableCeil text="Product Type" />
                                    <TableCeil>
                                        <DropdownWrapper
                                            uniqueKey={`${formula?.id}`}
                                            defaultLabel=""
                                            name="product_type"
                                            value={values?.product_type?.value}
                                            onChange={handleChangeValue}
                                            options={productTypesData}
                                        />
                                    </TableCeil>
                                </TableRow>
                                <TableRow>
                                    <TableCeil text="Flammability Code" />
                                    <TableCeil>
                                        <Input
                                            name="flammability_code"
                                            value={
                                                values?.flammability_code?.value
                                            }
                                            onChange={handleChangeValue}
                                            // isDisabled={!isOwner}
                                        />
                                    </TableCeil>
                                </TableRow>
                                <TableRow>
                                    <TableCeil text="pH Range" />
                                    <TableCeil className="flex">
                                        <input
                                            type="text"
                                            className="input input-bordered w-1/3"
                                            name="initial_ph_min"
                                            value={values.initial_ph_min.value}
                                            // disabled={!isOwner}
                                            onChange={handleChangeValue}
                                        />
                                        <span className="m-2">to</span>
                                        <input
                                            type="text"
                                            className="input input-bordered w-1/3"
                                            name="initial_ph_max"
                                            value={values?.initial_ph_max.value}
                                            // disabled={!isOwner}
                                            onChange={handleChangeValue}
                                        />
                                    </TableCeil>
                                </TableRow>
                                <TableRow>
                                    <TableCeil text="Status" />
                                    <TableCeil>
                                        <DropdownWrapper
                                            name="status"
                                            value={values?.status?.value}
                                            options={formulaStatusOptions}
                                            onChange={handleChangeValue}
                                            isDefaultOption={false}
                                        />
                                    </TableCeil>
                                </TableRow>
                            </tbody>
                        </table>
                    </div>
                    <div className="md:w-1/2 lg:w-1/2 md:ml-5">
                        <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                            <tbody>
                                <TableRow>
                                    <TableCeil text="Emulsion" />
                                    <TableCeil className="flex gap-3">
                                        <CheckboxLabel
                                            name="emulsion"
                                            value="yes"
                                            isChecked={
                                                values?.emulsion?.value ===
                                                "yes"
                                            }
                                            onChange={handleChangeValue}
                                            label="YES"
                                            // disabled={!isOwner}
                                        />
                                        <CheckboxLabel
                                            name="emulsion"
                                            value="no"
                                            isChecked={
                                                values?.emulsion?.value === "no"
                                            }
                                            onChange={handleChangeValue}
                                            label="NO"
                                            // disabled={!isOwner}
                                        />
                                        <CheckboxLabel
                                            name="emulsion"
                                            value="not_applicable"
                                            isChecked={
                                                values?.emulsion?.value ===
                                                "not_applicable"
                                            }
                                            onChange={handleChangeValue}
                                            label="NOT APPLICABLE"
                                            // disabled={!isOwner}
                                        />
                                    </TableCeil>
                                </TableRow>
                                <TableRow>
                                    <TableCeil text="Physical State" />
                                    <TableCeil>
                                        <select
                                            className="select select-bordered w-full"
                                            name="physical_state"
                                            value={
                                                values?.physical_state?.value
                                            }
                                            onChange={handleChangeValue}
                                            // disabled={!isOwner}
                                        >
                                            <option value="" />
                                            <option value="solid">Solid</option>
                                            <option value="liquid">
                                                Liquid
                                            </option>
                                            <option value="gas">Gas</option>
                                        </select>
                                    </TableCeil>
                                </TableRow>
                            </tbody>
                        </table>
                        {Boolean(formula?.rawIngredients.length) && (
                            <div className="flex flex-col gap-2">
                                <Title
                                    className="pb-1"
                                    text="The following values were calculated based on the included raw ingredient and INCI"
                                />
                                <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                                    <tbody>
                                        <TableRow>
                                            <TableCeil text="Animal derived" />
                                            <TableCeil>
                                                {
                                                    formulaeExtraInfo.animalDerived
                                                }
                                            </TableCeil>
                                        </TableRow>
                                        <TableRow>
                                            <TableCeil text="Contains Gluten" />
                                            <TableCeil>
                                                {formulaeExtraInfo.gluten}
                                            </TableCeil>
                                        </TableRow>
                                        <TableRow>
                                            <TableCeil text="Cruelty Free" />
                                            <TableCeil>
                                                {formulaeExtraInfo.crueltyFree}
                                            </TableCeil>
                                        </TableRow>
                                        <TableRow>
                                            <TableCeil text="Vegan" />
                                            <TableCeil>
                                                {formulaeExtraInfo.vegan}
                                            </TableCeil>
                                        </TableRow>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </form>
            </div>

            <div className="md:flex lg:flex lg:pb-5 md:mx-5 lg:mx-10 my-3">
                {(isOwner || true) && (
                    <Button
                        text="Save formula information"
                        onClick={handleUpdateFormula}
                        isLoading={isPending}
                    />
                )}
            </div>
        </div>
    );
};
