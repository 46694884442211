import React, { FC, useRef } from "react";
import { useGetRawIngredient } from "../queries";
import { ModalTemplate } from "components/shared/modalTemplate";
import { IRawIngredientResponse } from "pages/rawIngredient/types";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { CreateRawIngredientForm } from "pages/rawIngredient/rawIngredients/components/CreateRawIngredientForm";
import { EditIcon } from "assets/icons";
import { useRawIngredientStore } from "../store";

interface IProps {
    refetch?: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<IRawIngredientResponse, Error>>;
}

export const Header: FC<IProps> = ({ refetch }) => {
    const rawIngredient = useGetRawIngredient();
    const { isOwner } = useRawIngredientStore();

    const updateRawIngredientRef = useRef<HTMLDialogElement>(null);
    const openModal = () => {
        if (updateRawIngredientRef.current) {
            updateRawIngredientRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (updateRawIngredientRef.current) {
            refetch && refetch();
            updateRawIngredientRef.current.close();
        }
    };

    return (
        <>
            <ModalTemplate
                text="Edit raw ingredient"
                onClose={closeModal}
                modalRef={updateRawIngredientRef}
                className="max-w-[600px]"
            >
                {rawIngredient && (
                    <CreateRawIngredientForm
                        rawIngredient={rawIngredient}
                        handleClose={closeModal}
                    />
                )}
            </ModalTemplate>
            <div
                className="md:flex lg:flex p-3 mt-10 mx-10 justify-between rounded-lg"
                style={{ backgroundColor: "rgb(237 233 254)" }}
            >
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        {rawIngredient?.ricode}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        <b>{rawIngredient?.description}</b>
                    </span>
                </div>
                <div>
                    {(isOwner || true) && (
                        <button onClick={openModal}>
                            <EditIcon />
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
