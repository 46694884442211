import React, { FC, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { useCreateFormulaBulk } from "pages/formulas/formulas/queries";
import { InputWrapper, Button } from "components/shared";
import { IParsedFormula } from "../UploadFormula";
import { RawIngredientItem } from "../RawIngredientItem";
import { validationSchema } from "../schema";
import BigNumber from "bignumber.js";
import { AddRawIngredient } from "../AddRawIngredient";
import {
    defaultSuccessToastUpdate,
    defaultErrorToastUpdate,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";

interface IProps {
    initialValues: IParsedFormula;
    onClose: () => void;
}

interface IErrors extends IParsedFormula {
    totalRawWeight: string;
}

export const UploadFormulaForm: FC<IProps> = ({ initialValues, onClose }) => {
    const [totalRawWeight, setTotalRawWeight] = useState(0);
    const [isVisibleCreateDialog, setIsVisibleCreateDialog] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const queryClient = useQueryClient();

    const { mutate } = useCreateFormulaBulk();

    const handleToggleDialog = () => {
        setIsVisibleCreateDialog(!isVisibleCreateDialog);
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values, { setFieldError }) => {
            if (+totalRawWeight > 100) {
                setFieldError(
                    "totalRawWeight",
                    "Total weight percent of rawIngredients must not exceed 100",
                );

                return;
            }

            const preparedData = {
                ...values,
                rawIngredients: values.rawIngredients.map((el) => {
                    return !el.isExist || el.isOwner
                        ? el
                        : { ...el, contaminants: [], subIngredients: [] };
                }),
            };

            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            mutate(
                { body: preparedData },
                {
                    onSuccess(data: unknown) {
                        const { description } = data as {
                            description: string;
                        };
                        setFieldError("description", description);

                        if (!description) {
                            toast.update(toastId, {
                                ...defaultSuccessToastUpdate,
                                render: "Formula has been created",
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.formulas],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.formula],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.rawIngredients],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.subIngredients],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.contaminants],
                            });
                            onClose();
                        } else {
                            toast.update(toastId, defaultErrorToastUpdate);
                        }
                    },
                    onError() {
                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const { values, touched, handleChange, handleSubmit, setFieldValue } =
        formik;
    const errors: FormikErrors<IErrors> = formik.errors;

    const calculateTotalRawWeight = () => {
        const value = values.rawIngredients?.reduce((acc, item) => {
            return new BigNumber(acc).plus(item.weight_percent).toNumber();
        }, 0);
        setTotalRawWeight(value);
    };

    useEffect(() => {
        calculateTotalRawWeight();

        return () => {
            setSubmitCount(0);
        };
    }, [values.rawIngredients]);

    return (
        <>
            <FormikProvider value={formik}>
                <form
                    className="relative"
                    onSubmit={(e) => {
                        setSubmitCount((curr) => curr + 1);
                        handleSubmit(e);
                    }}
                >
                    <div className="flex space-x-2">
                        <InputWrapper
                            isError={Boolean(
                                errors.description && touched.description,
                            )}
                            error={errors.description}
                            label="Description"
                            className="!w-full !p-0"
                        >
                            <input
                                type="text"
                                name="description"
                                className="w-full input input-bordered"
                                value={values.description}
                                onChange={handleChange}
                            />
                        </InputWrapper>
                    </div>
                    <div className="w-full my-4 font-bold">
                        Included RawIngredients
                    </div>
                    <div className="w-full flex justify-start mb-4">
                        <Button
                            text="Add Raw Ingredient"
                            onClick={handleToggleDialog}
                            disabled={totalRawWeight >= 100}
                        />
                    </div>
                    <FieldArray name="rawIngredients">
                        {({ remove }) => (
                            <div className="flex flex-col gap-2">
                                {isVisibleCreateDialog && (
                                    <AddRawIngredient
                                        onClose={handleToggleDialog}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                )}

                                {values.rawIngredients.map((item, index) => (
                                    <RawIngredientItem
                                        key={index}
                                        index={index}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                        item={item}
                                        removeRawIngredient={remove}
                                        setFieldValue={setFieldValue}
                                    />
                                ))}
                            </div>
                        )}
                    </FieldArray>
                    <div className="w-full py-2 text-[#dc2626] flex flex-col gap-1">
                        <span>
                            {Boolean(
                                errors &&
                                    Boolean(Object.keys(errors).length) &&
                                    submitCount &&
                                    !errors.totalRawWeight,
                            ) &&
                                "An error occurred! Please check the provided data and ensure it meets the required format and criteria."}
                        </span>
                        <span>
                            {errors.totalRawWeight && errors.totalRawWeight}
                        </span>
                    </div>
                    <div className="w-full my-4 flex justify-end items-center">
                        <Button type="submit" text="Create Formula" />
                    </div>
                </form>
            </FormikProvider>
        </>
    );
};
