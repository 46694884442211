import { ArchiveAdd } from "assets/icons";
import { checkItemsId } from "common/utils";
import React, { useMemo } from "react";

interface IProps<T> {
    id: number;
    onAction: (id: number) => void;
    items: T[];
    attribute: keyof T;
    className?: string;
    isDisabled?: boolean;
}

export const SaveButton = <T,>({
    id,
    onAction,
    items,
    attribute,
    className = "",
    isDisabled = false,
}: IProps<T>) => {
    const isSaved = useMemo(
        () => checkItemsId(items, id, attribute),
        [id, items, attribute],
    );

    const fillColor = isSaved ? "#113261" : "none";

    return (
        <div className={`flex items-center px-2 ${className}`}>
            <button
                className="text-secondary-info"
                onClick={() => onAction(id)}
                disabled={isDisabled && isSaved}
                aria-label={`Save formula ${id}`}
            >
                <ArchiveAdd fill={fillColor} size={24} />
            </button>
        </div>
    );
};
