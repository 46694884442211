// import { CloseIcon } from "assets/icons";
import React from "react";

export const Header = () => (
    <div className="grid lg:grid-cols-2 gap-4 mx-10 my-8">
        <div>
            <h2 className="text-2xl font-semibold">Dashboard</h2>
            <h6 className="text-primary-main-gray text-sm md:text-base lg:text-base">
                Welcome to the SmartSafety Dashboard! Here, you can quickly view
                an overview of the database counts and conveniently access your
                recent searches.
            </h6>
        </div>
        {/* <div
            className="flex bg-white border-l-4 border-indigo-500 text-gray-700 py-2 my-3 rounded relative "
            role="alert"
        >
            <span className="block text-xs md:text-sm lg-text-base sm:inline pl-4">
                Alerts or notifications will be here.
            </span>
            <span className="absolute right-0 ">
                <CloseIcon color="black" className="!h-4 !w-4" />
            </span>
        </div> */}
    </div>
);
