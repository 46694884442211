import React, { FC, ChangeEvent } from "react";
import { Tooltip } from "react-tooltip";
import { InputWrapper } from ".";
import { IMosIngredientData } from "../types";
import { DropdownWrapper } from "components/shared";
import { useGetAllProductTypes } from "pages/formulas/formula-profile/queries";

interface IProps {
    values: IMosIngredientData;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const Form: FC<IProps> = ({ values, onChange }) => {
    const { data: productTypesData } = useGetAllProductTypes();

    return (
        <form>
            <div className="flex flex-row text-sm">
                <InputWrapper className="mb-2" label="Product type">
                    <DropdownWrapper
                        value={values.product_type.value}
                        defaultLabel=""
                        name="product_type"
                        onChange={onChange}
                        disabled={values.product_type.disabled}
                        options={productTypesData ?? []}
                    />
                </InputWrapper>
                <InputWrapper label="Grams applied/day">
                    <input
                        id="grams-applied-day-tooltip"
                        type="text"
                        className="w-full input input-bordered"
                        name="grams_applied_per_day"
                        value={values.grams_applied_per_day.value}
                        onChange={onChange}
                        disabled={values.grams_applied_per_day.disabled}
                    />
                </InputWrapper>
            </div>
            <div className="flex flex-row text-sm mt-1 mb-2">
                <InputWrapper label="Body weight">
                    <input
                        type="text"
                        className="w-full input input-bordered"
                        name="body_weight"
                        value={values.body_weight.value}
                        onChange={onChange}
                        disabled={values.body_weight.disabled}
                    />
                </InputWrapper>
                <InputWrapper label="Skin retention factor">
                    <input
                        type="text"
                        id="skin-retention-factor"
                        className="w-full input input-bordered"
                        name="skin_retention_factor"
                        value={values.skin_retention_factor.value}
                        onChange={onChange}
                        disabled={values.skin_retention_factor.disabled}
                    />
                </InputWrapper>
            </div>

            <Tooltip
                anchorSelect="#grams-applied-day-tooltip"
                content="This factor is calculated based on average usage data for the selected product type."
                variant="info"
            />
            <Tooltip
                anchorSelect="#skin-retention-factor"
                content="This factor is calculated based on product formulation and application method."
                variant="info"
                place="bottom"
            />
        </form>
    );
};
