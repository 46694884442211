import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormulaProfileTab, IPreparedSubIngredient, YesNoMaybe } from "./types";
import { Header } from "./components";
import { TabContent } from "./components/tabs";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { TabsBox } from "components/tabsBox";
import { useRemoveFormula, useUploadFormula } from "./queries";
import BigNumber from "bignumber.js";
import { useFormulaProfileState } from "./store";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";

const tabs: FormulaProfileTab[] = [
    "Properties",
    "Composition",
    "MOS",
    "Reports",
    "Testing",
    "Similarity",
];

interface IPreparedObj {
    [key: string]: IPreparedSubIngredient;
}

export const FormulaProfile = () => {
    const { id = "" } = useParams();
    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(search);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { keycloak } = useKeycloak();

    const [selectedTab, setSelectedTab] = useState(
        (searchParams.get("tab") as FormulaProfileTab) || tabs[0],
    );

    const {
        data: formula,
        isLoading,
        isError,
        refetch,
    } = useUploadFormula({ id });

    const {
        formulaeExtraInfo,
        clearState,
        setFormulaExtraInfo,
        setRawIngredients,
        setContaminants,
        setSubIngredients,
        setSubIngredientsTotalWeight,
        setTotalEnvironmentalScore,
        setRawIngredientsTotalWeight,
        setIsOwner,
        isOwner,
        setKey,
    } = useFormulaProfileState();

    useEffect(() => {
        if (id) {
            const queryKey = [queryKeys.formula, id];
            setKey(queryKey);
        }
    }, [id]);

    useEffect(() => {
        refetch();
        return () => {
            clearState();
        };
    }, []);

    useEffect(() => {
        if (formula) {
            clearState();
            const preparedObj: IPreparedObj = {};
            let totalRawIngredientWeightPercent = "0";
            const rawIngredients =
                formula.rawIngredients.map((rawIngredient) => {
                    totalRawIngredientWeightPercent = new BigNumber(
                        totalRawIngredientWeightPercent,
                    )
                        .plus(rawIngredient.formRaw.weight_percent)
                        .toString();

                    return {
                        ...rawIngredient,
                        formRaw: {
                            ...rawIngredient.formRaw,
                            weight_percent: new BigNumber(
                                rawIngredient.formRaw.weight_percent,
                            ).toString(),
                        },
                    };
                }) || [];

            setRawIngredientsTotalWeight(totalRawIngredientWeightPercent);
            setRawIngredients(rawIngredients);

            const contaminants =
                formula.contaminants.map((rawIngredient) => {
                    return {
                        ...rawIngredient,
                        formulasContaminants: {
                            ...rawIngredient.formulasContaminants,
                            ppm: new BigNumber(
                                rawIngredient.formulasContaminants.ppm,
                            ).toString(),
                        },
                    };
                }) || [];

            setContaminants(contaminants);

            if (!Boolean(formula.rawIngredients)) {
                return;
            }

            let subIngredientsTotalWeight = "0";
            let totalEnvironmentalScore = "0";

            formula.rawIngredients.forEach((rawIngredient) => {
                if (rawIngredient.animal_derived === "yes") {
                    setFormulaExtraInfo("animalDerived", "Yes");
                    return true;
                }
            });

            if (
                formula.rawIngredients.some((it) => it.animal_derived === "yes")
            ) {
                setFormulaExtraInfo("animalDerived", "Yes");
                setFormulaExtraInfo("vegan", "No");
            }

            if (
                formula.rawIngredients.every((it) => it.animal_derived === "no")
            ) {
                setFormulaExtraInfo("animalDerived", "No");
                setFormulaExtraInfo("vegan", "Yes");
            }

            if (
                formula.rawIngredients.every(
                    (it) => it.animal_derived === "not_available",
                )
            ) {
                setFormulaExtraInfo("animalDerived", "Not available");
                setFormulaExtraInfo("vegan", "Not available");
            }

            for (const rawIngredient of formula.rawIngredients) {
                const rawWeight = new BigNumber(
                    rawIngredient.formRaw.weight_percent,
                ).div(100);

                if (formulaeExtraInfo.crueltyFree == "Yes") {
                    let crueltyFree: YesNoMaybe = formulaeExtraInfo.crueltyFree;

                    if (Boolean(rawIngredient.tradename)) {
                        for (const tradename of rawIngredient.tradename) {
                            if (tradename?.cfi_acceptable != "Yes") {
                                crueltyFree = "No";
                                break;
                            }
                        }
                    } else {
                        crueltyFree = "Not available";
                    }

                    setFormulaExtraInfo("crueltyFree", crueltyFree);
                }

                if (Boolean(rawIngredient.subIngredients?.length)) {
                    const subIngredients: IPreparedSubIngredient[] =
                        rawIngredient.subIngredients.map((subIngredient) => {
                            const raw_weight_percent = new BigNumber(
                                subIngredient.rawSub.weight_percent || "0",
                            )
                                .times(rawWeight)
                                .toString();

                            const environmental_score = !subIngredient
                                ?.toxicology?.overall_environmental_
                                ? "0"
                                : new BigNumber(raw_weight_percent)
                                      .times(
                                          subIngredient?.toxicology
                                              ?.overall_environmental_ || "0",
                                      )
                                      .div(100)
                                      .toString();

                            subIngredientsTotalWeight = new BigNumber(
                                subIngredientsTotalWeight,
                            )
                                .plus(raw_weight_percent)
                                .toString();

                            totalEnvironmentalScore = new BigNumber(
                                totalEnvironmentalScore,
                            )
                                .plus(environmental_score)
                                .toString();

                            if (
                                formulaeExtraInfo.gluten == "No" &&
                                subIngredient.gluten?.toLowerCase() == "yes"
                            ) {
                                setFormulaExtraInfo("gluten", "Yes");
                            }

                            return {
                                ...subIngredient,
                                environmental_score,
                                rawSub: {
                                    ...subIngredient.rawSub,
                                    raw_weight_percent,
                                },
                            } as unknown as IPreparedSubIngredient;
                        });

                    setTotalEnvironmentalScore(totalEnvironmentalScore);
                    setSubIngredientsTotalWeight(subIngredientsTotalWeight);

                    for (const ingredient of subIngredients) {
                        const key = ingredient.id;

                        if (preparedObj[key]) {
                            preparedObj[key].rawSub.raw_weight_percent =
                                new BigNumber(
                                    preparedObj[key].rawSub.raw_weight_percent,
                                )
                                    .plus(ingredient.rawSub.raw_weight_percent)
                                    .toString();
                            preparedObj[key].environmental_score =
                                new BigNumber(
                                    preparedObj[key].environmental_score,
                                )
                                    .plus(ingredient.environmental_score)
                                    .toString();
                        } else {
                            preparedObj[key] = ingredient;
                        }
                    }
                }
            }

            setSubIngredients(Object.values(preparedObj));
        }
    }, [formula, id]);

    const isUserOwner = useMemo(() => {
        return keycloak.tokenParsed?.email === formula?.created_by;
    }, [keycloak.tokenParsed?.email, formula?.created_by]);

    useEffect(() => {
        setIsOwner(true);

        return () => {
            setIsOwner(true);
        };
    }, [isUserOwner, setIsOwner]);

    const onSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab as FormulaProfileTab);
            searchParams.set("tab", tab);
            setSearchParams(searchParams);
        },
        [setSelectedTab],
    );

    const { mutate } = useRemoveFormula();

    const removeFormula = useCallback(() => {
        mutate(
            { id },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.formulas],
                    });
                    navigate(routerKeys.formulas);
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.productClassification],
                    });
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [id]);

    const onRemove = useCallback(() => {
        // if (isOwner) {
        return removeFormula;
        // }
    }, [removeFormula, isOwner]);

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!formula || isError) {
        toast.error(toastTexts.error, defaultErrorToastOptions);
        return <ToastContainer />;
    }

    return (
        <>
            <Header refetch={refetch} />
            <TabsBox
                tabs={tabs}
                onSelectTab={onSelectTab}
                selectedTab={selectedTab}
                onRemove={onRemove()}
            />
            <TabContent selectedTab={selectedTab} />
            {<ToastContainer />}
        </>
    );
};
