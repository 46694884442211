import React from "react";
import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { TabTable } from "../../../TabTable";
import { useFetchRawIngredientTradenames } from "pages/rawIngredient/rawIngredientProfile/queries";
import { useParams } from "react-router-dom";
import { ManufacturerRow } from "./ManufacturerRow";

export const ManufacturesTable = () => {
    const { id = "" } = useParams();
    const { data: tradenames } = useFetchRawIngredientTradenames(id);

    return (
        <TabTable>
            <thead>
                <tr className="border-b border-primary-main-dgray">
                    <TableHeaderCeil text="Code" />
                    <TableHeaderCeil text="Manufacturer" />
                    <TableHeaderCeil text="Trade Name" />
                    <TableHeaderCeil />
                </tr>
            </thead>
            <tbody>
                {Boolean(tradenames?.length) ? (
                    tradenames
                        ?.sort((a, b) => a.tradename.localeCompare(b.tradename))
                        ?.map((tradename) => (
                            <ManufacturerRow
                                key={tradename.id}
                                tradename={tradename}
                            />
                        ))
                ) : (
                    <tr>
                        <td className="p-3">No Manufacturers found</td>
                    </tr>
                )}
            </tbody>
        </TabTable>
    );
};
