import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import classNames from "classnames";
import { routerKeys } from "common/constants/routerKeys";
import Note from "assets/images/icons/document-text.svg";
import Stack from "assets/images/icons/stickynote.svg";
import Stick from "assets/images/icons/note-2-1.svg";
import Settings from "assets/images/icons/setting-2.svg";
import Category from "assets/images/icons/category.svg";
import User from "assets/images/icons/user.svg";
import { BurgerIcon, CloseIcon } from "assets/icons";
import { DesktopLink } from "./components";
import { keycloakService } from "services/keycloakService";
import "./styles.scss";

export const Navbar = () => {
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);
    const { keycloak } = useKeycloak();

    const isAuthenticated = useMemo(
        () => Boolean(keycloak.authenticated),
        [keycloak.authenticated],
    );

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserProfile().then((payload) => {
                const { id, email } = payload;

                if (!id || !email) {
                    return;
                }

                keycloakService.assignGroup({
                    id,
                    email,
                });
            });
        }
    }, [keycloak.authenticated]);

    const handleMenuVisibility = () => {
        setIsVisibleMenu(!isVisibleMenu);
    };

    return (
        <nav className="bg-header">
            <div className="mx-auto px-4">
                <div className="flex justify-between items-center">
                    <div className="flex space-x-4">
                        <NavLink
                            to={routerKeys.formulas}
                            className="flex item-center py-3 px-3 text-gray-100 hover:text-primary-main-white"
                        >
                            <span className="font-bold text-25px">
                                SmartSafety
                            </span>
                        </NavLink>
                    </div>
                    {isAuthenticated && (
                        <>
                            <div className="flex-initial align-middle">
                                <div className="hidden md:flex items-center space-x-1">
                                    <DesktopLink
                                        text="Dashboard"
                                        image={Category}
                                        to={routerKeys.dashboard}
                                    />
                                    <DesktopLink
                                        text="Formulas"
                                        image={Note}
                                        to={routerKeys.formulas}
                                    />
                                    <DesktopLink
                                        text="Raw Ingredient"
                                        image={Stack}
                                        to={routerKeys.rawIngredient}
                                    />
                                    <DesktopLink
                                        text="INCI"
                                        image={Stick}
                                        to={routerKeys.subIngredient}
                                    />
                                    <DesktopLink
                                        text="Contaminants"
                                        image={Stick}
                                        to={routerKeys.contaminants}
                                    />
                                    {/* 
                                    <NavLink
                                        to={ROUTER_KEYS.REPORT}
                                        className={({ isActive }) =>
                                            classNames("nav-link", {
                                                "bg-primary-brand-mid":
                                                    isActive,
                                            })
                                        }
                                    >
                                        <img
                                            src="../../assets/images/icons/pie.svg"
                                            className="h-6"
                                            alt=""
                                        />
                                        Report
                                    </NavLink> */}
                                </div>
                            </div>
                            <div className="hidden md:flex item-center space-x-1 mr-2">
                                <DesktopLink
                                    className="min-w-5 mx-2 px-1"
                                    text=""
                                    image={User}
                                    to={routerKeys.profile}
                                />
                                <DesktopLink
                                    className="min-w-5 mx-2 px-1"
                                    text=""
                                    image={Settings}
                                    to={routerKeys.adminPanel}
                                />
                            </div>
                        </>
                    )}

                    {/* <!-- mobile button --> */}
                    {isAuthenticated && (
                        <div className="md:hidden flex item-center">
                            <button
                                className="mobile-menu-button"
                                onClick={handleMenuVisibility}
                            >
                                {isVisibleMenu ? <CloseIcon /> : <BurgerIcon />}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* <!-- mobile menu --> */}

            <div
                className={classNames("mobile-menu", "md:hidden", {
                    hidden: !isVisibleMenu && isAuthenticated,
                })}
            >
                {/* <NavLink to={ROUTER_KEYS.DASHBOARD} className="mobile-link">
                  Dashboard
                </NavLink> */}
                <NavLink to={routerKeys.formulas} className="mobile-link">
                    Formulas
                </NavLink>
                <NavLink to={routerKeys.rawIngredient} className="mobile-link">
                    Raw Ingredient
                </NavLink>
                <NavLink to={routerKeys.subIngredient} className="mobile-link">
                    INCI
                </NavLink>
                {/* <NavLink to={ROUTER_KEYS.CONTAMINANTS} className='mobile-link'>

                </NavLink>
                <NavLink to={ROUTER_KEYS.REPORT} className='mobile-link'>
                  Report
                </NavLink> */}
                <NavLink
                    to={routerKeys.adminPanel}
                    className="mobile-link my-10 font-bold"
                >
                    Admin Panel
                </NavLink>
            </div>
        </nav>
    );
};
