import { Button } from "components/shared/button";
import { ModalTemplate } from "components/shared/modalTemplate";
import {
    useGetFormula,
    useRemoveFormulaReport,
} from "pages/formulas/formula-profile/queries";
import { toast } from "react-toastify";
import React, { useRef } from "react";
import {
    defaultErrorToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { useQueryClient } from "@tanstack/react-query";
import { handleDownloadFile } from "common/utils";
import { formulaService } from "services";
import { IReport } from "common/types";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    reportFile: IReport;
}

export const ReportRow: React.FC<IProps> = ({ reportFile }) => {
    const formula = useGetFormula();
    const modalRef = useRef<HTMLDialogElement>(null);
    const queryClient = useQueryClient();

    const { mutate: removeFormulaReport } = useRemoveFormulaReport();
    const { isOwner } = useFormulaProfileState();

    const openModal = () => {
        if (modalRef.current) {
            modalRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (modalRef.current) {
            modalRef.current.close();
        }
    };

    const handleDownload = async () => {
        if (!isOwner) {
            return;
        }

        formulaService
            .getFormulaReport({
                ficode: formula?.ficode || "",
                filename: reportFile.filename,
            })
            .then((response) => {
                handleDownloadFile(response, reportFile.filename);
            })
            .catch(() => {
                toast.error(toastTexts.error, defaultErrorToastOptions);
            });
    };

    const deleteFile = () => {
        const key = [queryKeys.formulaReports, formula?.ficode || ""];
        closeModal();

        removeFormulaReport(
            { ficode: formula?.ficode || "", filename: reportFile.filename },
            {
                onSuccess: () => {
                    queryClient.setQueryData<IReport[]>(key, (prevData) =>
                        prevData?.filter(
                            (file) =>
                                file.filename !== reportFile.filename &&
                                file.modified !== reportFile.modified,
                        ),
                    );
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    };

    return (
        <>
            <ModalTemplate
                modalRef={modalRef}
                text="Do you really want to delete the report?"
                onClose={closeModal}
                className="max-w-md pt-10"
            >
                <div className="flex flex-col justify-end">
                    {(isOwner || true) && (
                        <div className="flex items-center justify-end gap-2">
                            <Button
                                text="No"
                                onClick={closeModal}
                                className="btn-error"
                            />
                            <Button text="Yes, delete" onClick={deleteFile} />
                        </div>
                    )}
                </div>
            </ModalTemplate>
            <tr>
                <td className="w-3/4 text-xs md:text-base lg:text-base p-3">
                    <a
                        className="cursor-pointer hover:font-bold"
                        onClick={handleDownload}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                        <span className="ml-2">
                            {reportFile.filename} (
                            {new Date(reportFile.modified).toLocaleDateString(
                                "en-US",
                                {
                                    dateStyle: "medium",
                                },
                            )}
                            )
                        </span>
                    </a>
                </td>
                <td className="flex p-3">
                    <button
                        className="btn btn-xs"
                        data-file={reportFile.filename}
                        onClick={openModal}
                    >
                        delete
                    </button>
                </td>
            </tr>
        </>
    );
};
