import classNames from "classnames";
import React, { memo } from "react";

interface IProps {
    text: string;
    className?: string;
}

export const TableTitleCeil: React.FC<IProps> = memo(({ text, className }) => (
    <td
        className={classNames(
            "text-xs md:text-base lg:text-base p-3 max-w-[200px] break-words",
            className,
        )}
    >
        <b>{text}</b>
    </td>
));
