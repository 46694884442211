import React, { FC } from "react";
import classNames from "classnames";

interface IProps {
    children: React.ReactNode;
    label: string;
    className?: string;
}

export const InputWrapper: FC<IProps> = ({
    children,
    label,
    className = "",
}) => (
    <>
        <div className="w-2/12 p-3 ml-2">
            <label className="pt-3">{label}</label>
        </div>
        <div className={classNames("w-4/12", className)}>{children}</div>
    </>
);
