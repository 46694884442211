import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import {
    fdaService,
    // formulaService,
    formulasService,
    rawIngredientService,
    rawIngredientsService,
    subIngredientsService,
    vendorService,
} from "services";
// import { ICreateFormula } from "../types";
import { ICreateRawIngredientBody } from "pages/rawIngredient/types";
import { ICreateSubIngredientBody } from "pages/subIngredient/subIngredients/libs/types";
import { IVendor } from "common/types";

export const useUploadProductClassification = () => {
    return useQuery({
        queryKey: [queryKeys.productClassification],
        queryFn: () => formulasService.getProductTypes(),
    });
};

// export const useCreateFormula = () => {
//     return useMutation({
//         mutationFn: ({ body }: { body: ICreateFormula }) =>
//             formulaService.createFormula({ body }),
//     });
// };

export const useCreateVendor = () => {
    return useMutation({
        mutationFn: ({ body }: { body: Pick<IVendor, "description"> }) =>
            vendorService.create({ body }),
    });
};

export const useCreateRawIngredient = () => {
    return useMutation({
        mutationFn: ({ body }: { body: ICreateRawIngredientBody }) =>
            rawIngredientService.createRawIngredient({ body }),
    });
};

export const useCreateSubIngredient = () => {
    return useMutation({
        mutationFn: ({ body }: { body: ICreateSubIngredientBody }) =>
            subIngredientsService.createSubIngredient({ body }),
    });
};

export const useGetAllFormulas = () => {
    return useQuery({
        queryKey: [queryKeys.formulas, "all"],
        queryFn: () => formulasService.getAllFormulas(),
    });
};

export const useGetAllRawIngredients = () => {
    return useQuery({
        queryKey: [queryKeys.rawIngredients, "all"],
        queryFn: () => rawIngredientsService.getAll(),
        select: (data) => {
            return data.filter(({ status }) => status === "Complete");
        },
    });
};

export const useRemoveFdaClass = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id }: { id: string }) =>
            fdaService.removeFdaClass({ id }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.fdaClasses] });
        },
    });
};

export const useGetFdaClasses = () =>
    useQuery({
        queryKey: [queryKeys.fdaClasses],
        queryFn: () => fdaService.getAll(),
    });

export const useGetAllProductTypes = () => {
    return useQuery({
        queryKey: [queryKeys.productTypes],
        queryFn: () => fdaService.getAllProductTypes(),
        select: (data) =>
            data.map(({ id, name }) => ({ key: id, value: name })),
    });
};

export const useRemoveProductType = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: string) => fdaService.removeProductType(id),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.productTypes],
            });
        },
    });
};
