import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import {
    subIngredientService,
    subIngredientsService,
    safetySummaryService,
} from "services";
import { useSubIngredientStore } from "../store";
import {
    DeparturePoint,
    ISubIngredientResponse,
    ReportEndpointKey,
    UpdateSubIngredientRequest,
} from "../types";
import { IOption, IToxicology } from "common/types";

export const useUploadSubIngredient = (id: string) => {
    const queryKey = [queryKeys.subIngredients, id];

    return useQuery({
        queryKey,
        queryFn: () => subIngredientService.getSubIngredient({ id }),
    });
};

export const useUploadEnvironmentalClass = () => {
    return useQuery({
        queryKey: [queryKeys.subIngredientsEnvironmentalClass],
        queryFn: () => subIngredientsService.getEnvironmentalClassOptions(),
    });
};

export const useGetFunctionOptions = () => {
    return useQuery<IOption[]>({
        queryKey: [queryKeys.subIngredientsFunctionOptions],
        queryFn: () => subIngredientsService.getFunctionOptions(),
    });
};

export const useUpdateSubIngredient = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: string;
            body: UpdateSubIngredientRequest;
        }) => subIngredientService.update({ id, body }),
    });
};

export const useCalculateEnvEndpoint = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: string;
            body: Partial<IToxicology>;
        }) => subIngredientService.calculateEnvEndpoint({ id, body }),
    });
};

export const useAddSafetyEndpoint = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: string;
            body: { safetyEndpoint: ReportEndpointKey };
        }) => subIngredientService.addSafetyEndpoint({ id, body }),
    });
};

export const useGetSubIngredient = () => {
    const { key } = useSubIngredientStore();
    const queryClient = useQueryClient();

    return queryClient.getQueryData<ISubIngredientResponse>(key);
};

export const useGetSubIngredientReports = ({ sicode }: { sicode: string }) => {
    return useQuery({
        queryKey: [queryKeys.subIngredientReports, sicode],
        queryFn: () => subIngredientService.getReports({ sicode }),
    });
};

export const useAddSubIngredientReport = () => {
    return useMutation({
        mutationFn: ({
            sicode,
            filename,
            body,
        }: {
            sicode: string;
            filename: string;
            body: FormData;
        }) =>
            subIngredientService.uploadReport({
                sicode,
                filename,
                body,
            }),
    });
};

export const useRemoveSubIngredientReport = () => {
    return useMutation({
        mutationFn: ({
            sicode,
            filename,
        }: {
            sicode: string;
            filename: string;
        }) =>
            subIngredientService.removeReport({
                sicode,
                filename,
            }),
    });
};

export const useRemoveSubIngredient = () =>
    useMutation({
        mutationFn: ({ id }: { id: string }) =>
            subIngredientService.removeSubIngredient({ id }),
    });

export const useCrammerClassOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.crammerClassOptions],
        queryFn: () => subIngredientsService.getCrammerClassOptions(),
    });

export const useGetSafetySummaryOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.safetySummaryOptions],
        queryFn: () => safetySummaryService.getSafetySummaryOptions(),
        select: (data) => [
            ...data,
            { label: "Add your own", value: "add_value" },
        ],
    });

export const useGetToxicReferenceOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.toxicReferenceOptions],
        queryFn: () => safetySummaryService.getToxReferenceOptions(),
        select: (data) => [
            ...data,
            { label: "Add your own", value: "add_value" },
        ],
    });

export const useLocalEffectRefOptions = () =>
    useQuery<IOption[]>({
        queryKey: [queryKeys.localEffectRefOptions],
        queryFn: () => safetySummaryService.getLocalEffectRefOptions(),
        select: (data) => [
            ...data,
            { label: "Add your own", value: "add_value" },
        ],
    });

export const useCreateDeparturePoint = () =>
    useMutation({
        mutationFn: (payload: Partial<DeparturePoint>) =>
            safetySummaryService.createDeparturePoint(payload),
    });

export const useCreateToxicologicalReferenceValues = () =>
    useMutation({
        mutationFn: (payload: Partial<DeparturePoint>) =>
            safetySummaryService.createToxicologicalReferenceValues(payload),
    });

export const useCreateLocalEffectValues = () =>
    useMutation({
        mutationFn: (payload: Partial<DeparturePoint>) =>
            safetySummaryService.createLocalEffectValues(payload),
    });
