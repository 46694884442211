import React from "react";
import { SubIngredientTab } from "../../types";
import { BasicInfoTab } from "./basicInfoTab";
import { SafetySummaryTab } from "./safetySummaryTab";
import { ReportSummaryTab } from "./reportSummaryTab";
import { EnvironmentalSummaryTab } from "./enviromentalSummaryTab";

interface IProps {
    selectedTab: SubIngredientTab;
}

export const TabContent: React.FC<IProps> = ({ selectedTab }) => {
    const contentMap = {
        "Basic Information": <BasicInfoTab />,
        "Safety Summary": <SafetySummaryTab />,
        "Report Summary": <ReportSummaryTab />,
        "Environmental Summary": <EnvironmentalSummaryTab />,
    };

    return <>{contentMap[selectedTab]}</>;
};
