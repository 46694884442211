import React, { FC, useRef } from "react";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useGetContaminant } from "../queries";
import { EditIcon } from "assets/icons";
import { useContaminantStore } from "../store";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { UpdateContaminantForm } from "pages/contaminant/contaminants";
import { ICreateContaminantBody } from "pages/contaminant/contaminants/types";

interface IProps {
    refetch?: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<ICreateContaminantBody, Error>>;
}

export const Header: FC<IProps> = ({ refetch }) => {
    const contaminant = useGetContaminant();
    const { isOwner } = useContaminantStore();

    const updateContaminantRef = useRef<HTMLDialogElement>(null);

    const openModal = () => {
        if (updateContaminantRef.current) {
            updateContaminantRef.current.showModal();
        }
    };

    const closeModal = () => {
        if (updateContaminantRef.current) {
            refetch && refetch();
            updateContaminantRef.current.close();
        }
    };

    return (
        <>
            <ModalTemplate
                text="Edit Contaminant"
                onClose={closeModal}
                modalRef={updateContaminantRef}
                className="max-w-[600px]"
            >
                {contaminant && (
                    <UpdateContaminantForm
                        handleClose={closeModal}
                        contaminant={contaminant}
                    />
                )}
            </ModalTemplate>
            <div
                className="md:flex lg:flex p-3 mt-10 mx-10 justify-between rounded-lg"
                style={{ backgroundColor: "rgb(237 233 254)" }}
            >
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        {contaminant?.name}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        <b>{contaminant?.description}</b>
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base">
                        {(isOwner || true) && (
                            <button className="ml-2" onClick={openModal}>
                                <EditIcon />
                            </button>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
};
