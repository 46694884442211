import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { IManualInput, IMosIngredientData } from "./types";
import { Title } from "components/shared/title";
import { Form, Table } from "./components";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { productTypeProperties } from "common/data";
import BigNumber from "bignumber.js";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import {
    useGetFormula,
    useUpdateMos,
} from "pages/formulas/formula-profile/queries";
import { Button } from "components/shared/button";
import { toast } from "react-toastify";
import { defaultToastOptions, toastTexts } from "common/constants";
import { useQueryClient } from "@tanstack/react-query";

const DEFAULT_BODY_WEIGHT = "60";
const DAILY_EXPOSURE_MULTIPLIER = "1000";

export const MosTab = () => {
    const queryClient = useQueryClient();
    const formula = useGetFormula();
    const { key, subIngredients, isOwner } = useFormulaProfileState();

    const [mosSubIngredients, setMosSubIngredients] = useState<
        IPreparedSubIngredient[]
    >([]);
    const [decimals] = useState(4);
    const [manualInputs, setManualInputs] = useState<IManualInput[]>([]);

    const [mosIngredientData, setMosIngredientData] =
        useState<IMosIngredientData>({
            product_type: {
                value: formula?.product_type || "",
                disabled: !isOwner,
            },
            grams_applied_per_day: { value: "", disabled: false },
            body_weight: {
                value: formula?.body_weight ?? DEFAULT_BODY_WEIGHT,
                disabled: !isOwner,
            },
            skin_retention_factor: { value: "", disabled: false },
        });

    const getProductTypeProperties = (type: string) => {
        return (
            productTypeProperties[type] || {
                grams_applied_per_day: "0",
                skin_retention_factor: "0",
            }
        );
    };

    useEffect(() => {
        if (!mosSubIngredients.length && subIngredients) {
            setMosSubIngredients(subIngredients);
        }
    }, [subIngredients]);

    const calculateMos = useCallback(() => {
        const productType = mosIngredientData.product_type.value;

        if (!productType) {
            return;
        }

        const bodyWeight = mosIngredientData.body_weight.value;
        const gramsAppliedPerDay =
            mosIngredientData.grams_applied_per_day.value;
        const skinRetentionFactor =
            mosIngredientData.skin_retention_factor.value;

        setMosSubIngredients((curr) =>
            curr.map((subIngredient, index) => {
                const dermalPenetration =
                    manualInputs[index]?.dermalPenetration ||
                    subIngredient.dermal_penetration;
                const noael = manualInputs[index]?.noael || subIngredient.noael;

                const dailyExposure = new BigNumber(gramsAppliedPerDay)
                    .times(skinRetentionFactor)
                    .times(dermalPenetration)
                    .div(100)
                    .times(subIngredient.rawSub.raw_weight_percent)
                    .div(100)
                    .times(DAILY_EXPOSURE_MULTIPLIER);
                const systematicDailyExposure = dailyExposure.div(bodyWeight);

                return {
                    ...subIngredient,
                    skin_retention_factor: skinRetentionFactor,
                    daily_exposure: dailyExposure
                        .decimalPlaces(decimals)
                        .toString(),
                    systematic_daily_exposure: systematicDailyExposure
                        .decimalPlaces(decimals)
                        .toString(),
                    MOS: noael
                        ? new BigNumber(noael)
                              .div(systematicDailyExposure)
                              .decimalPlaces(decimals)
                              .toString()
                        : "",
                };
            }),
        );
    }, [setMosSubIngredients, mosIngredientData, manualInputs]);

    useEffect(() => {
        calculateMos();
    }, [mosIngredientData, calculateMos]);

    useEffect(() => {
        if (mosIngredientData.product_type.value) {
            const productTypeProperties = getProductTypeProperties(
                mosIngredientData.product_type.value,
            );

            setMosIngredientData((curr) => ({
                ...curr,
                grams_applied_per_day: {
                    ...curr.grams_applied_per_day,
                    value: productTypeProperties.grams_applied_per_day,
                },
                skin_retention_factor: {
                    ...curr.skin_retention_factor,
                    value: productTypeProperties.skin_retention_factor,
                },
            }));
        }
    }, [mosIngredientData.product_type.value]);

    const handleChangeValue = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = e.target;

        setMosIngredientData((curr) => ({
            ...curr,
            [name]: { ...curr[name as keyof IMosIngredientData], value },
        }));
    };

    const { mutate, isPending } = useUpdateMos();

    const handleUpdateMos = () => {
        mutate(
            {
                id: `${formula?.id}`,
                body: {
                    product_type: mosIngredientData.product_type.value || null,
                    body_weight: mosIngredientData.body_weight.value,
                    mosSubIngredients,
                },
            },
            {
                onSuccess: (data) => {
                    toast.success(
                        `${toastTexts.success} Formula has been updated.`,
                        defaultToastOptions,
                    );

                    queryClient.setQueryData(key, data);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultToastOptions);
                },
            },
        );
    };

    const handleManualInputChange = useCallback(
        (updatedInputs: IManualInput[]) => {
            setManualInputs(updatedInputs);
        },
        [],
    );

    return (
        <>
            <div className="mx-10 my-3">
                <div className="flex justify-between items-center">
                    <Title text="MOS Calculation" />
                    <a
                        href="https://ec.europa.eu/health/scientific_committees/consumer_safety/docs/sccs_s_006.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                    >
                        Reference Document for MOS Calculation
                    </a>
                </div>
                <Form values={mosIngredientData} onChange={handleChangeValue} />
                <Table
                    subIngredients={mosSubIngredients}
                    onManualInputChange={handleManualInputChange}
                />
                {(isOwner || true) && (
                    <Button
                        isLoading={isPending}
                        text="Save MOS"
                        onClick={handleUpdateMos}
                        className="mt-2"
                    />
                )}
            </div>
        </>
    );
};
