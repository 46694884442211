import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header, TabContent } from "./components";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { LoadingToast } from "components/shared/loadingToast";
import { SoftDeleteTabsBox } from "components/tabsBox";
import { RawIngredientProfileTabT } from "../types";
import { useRemoveRawIngredient, useUploadRawIngredient } from "./queries";
import { ToastContainer, toast } from "react-toastify";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { useRawIngredientStore } from "./store";
import { useQueryClient } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";
import { RawIngredientProfileTab } from "../enums";

const tabs: RawIngredientProfileTabT[] = Object.values(RawIngredientProfileTab);

export const RawIngredientProfile = () => {
    const { id = "" } = useParams();
    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(search);
    const [selectedTab, setSelectedTab] = useState<RawIngredientProfileTabT>(
        (searchParams.get("tab") as RawIngredientProfileTabT) || tabs[0],
    );
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { setIsOwner, isOwner, setKey } = useRawIngredientStore();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (id) {
            const queryKey = [queryKeys.rawIngredient, id];
            setKey(queryKey);
        }
    }, [id]);

    const {
        data: rawIngredient,
        isLoading,
        isError,
        refetch,
    } = useUploadRawIngredient({ id });

    const handleSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab as RawIngredientProfileTabT);
            searchParams.set("tab", tab);
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams],
    );

    const isUserOwner = useMemo(() => {
        return keycloak.tokenParsed?.email === rawIngredient?.created_by;
    }, [keycloak.tokenParsed?.email, rawIngredient?.created_by]);

    useEffect(() => {
        // setIsOwner(isUserOwner);
        setIsOwner(true);

        return () => {
            setIsOwner(false);
        };
    }, [isUserOwner, setIsOwner]);

    const { mutate } = useRemoveRawIngredient();

    const removeRawIngredient = useCallback(() => {
        mutate(
            { id },
            {
                onSuccess: () => {
                    (isOwner || true) &&
                        queryClient.invalidateQueries({
                            queryKey: [queryKeys.rawIngredients],
                        });
                    navigate(routerKeys.rawIngredient);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [id, isOwner]);

    const alertMsg = useMemo(
        () =>
            `Are you sure you want to mark ${rawIngredient?.description} as inactive?\n It will be removed from new contexts but remain in existing ones.`,
        [rawIngredient?.description],
    );

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!rawIngredient || isError) {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        } else {
            toast.info(toastTexts.dataLack, defaultErrorToastOptions);
        }

        return <ToastContainer />;
    }

    return (
        <>
            <Header refetch={refetch} />
            <SoftDeleteTabsBox
                tabs={tabs}
                selectedTab={selectedTab}
                onSelectTab={handleSelectTab}
                onMarkAsInactive={removeRawIngredient}
                inactivityNotification={alertMsg}
            />
            <TabContent selectedTab={selectedTab} />
            <ToastContainer />
        </>
    );
};
