import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { rawIngredientService, vendorService } from "services";
import { useRawIngredientStore } from "../store";
import {
    IRawIngredientResponse,
    IUpdateRawIngredientRequest,
} from "pages/rawIngredient/types";
import {
    ICreateTradeNameRequest,
    IUpdateTradeNameDto,
    UpdateManufacturerTradenameBody,
} from "common/types";
import { SafetyDocumentBody, SafetyDocumentDeleteBody } from "../libs";

export const useUploadRawIngredient = ({ id }: { id: string }) => {
    const queryKey = [queryKeys.rawIngredient, id];

    return useQuery({
        queryKey,
        queryFn: () => rawIngredientService.getRawIngredient({ id }),
    });
};

export const useUpdateRawIngredient = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: string;
            body: IUpdateRawIngredientRequest;
        }) => rawIngredientService.updateRawIngredient({ id, body }),
    });
};

export const useGetRawIngredient = () => {
    const { key } = useRawIngredientStore();
    const queryClient = useQueryClient();

    return queryClient.getQueryData<IRawIngredientResponse>(key);
};

export const useGetVendors = () => {
    const rawIngredient = useGetRawIngredient();

    return rawIngredient?.tradename?.map(({ vendor }) => vendor);
};

export const useRemoveRawIngredient = () => {
    return useMutation({
        mutationFn: ({ id }: { id: string }) =>
            rawIngredientService.removeRawIngredient({ id }),
    });
};

export const useUpdateRelation = () => {
    return useMutation({
        mutationFn: async ({
            body,
            id,
            ingredientId,
            relationType,
        }: {
            body: {
                value: string;
            };
            id: string;
            ingredientId: string;
            relationType: string;
        }) =>
            rawIngredientService.updateRelation({
                id,
                ingredientId,
                relationType,
                body,
            }),
    });
};

export const useDeleteRelation = () => {
    return useMutation({
        mutationFn: ({
            id,
            ingredientId,
            relationType,
        }: {
            id: string;
            ingredientId: string;
            relationType: string;
        }) =>
            rawIngredientService.deleteRelation({
                id,
                ingredientId,
                relationType,
            }),
    });
};

export const useUploadVendors = () =>
    useQuery({
        queryKey: [queryKeys.vendors],
        queryFn: () => vendorService.getAll(),
        select: (data) =>
            data.map(({ id, description }) => ({
                value: id,
                label: description,
            })),
    });

export const useFetchRawIngredientTradenames = (id: string) =>
    useQuery({
        queryKey: [queryKeys.tradenames, id],
        queryFn: () => rawIngredientService.getTradeNamesByRawIngredientId(id),
    });

export const useDeleteTradename = () => {
    return useMutation({
        mutationFn: (id: string | number) =>
            rawIngredientService.deleteRawIngredientTradeName(id),
    });
};

export const useUpdateTradenameMutation = () =>
    useMutation({
        mutationFn: (payload: UpdateManufacturerTradenameBody) =>
            rawIngredientService.updateManufacturerAndTradename(payload),
    });

export const useCreateRawIngredientTradeName = () => {
    return useMutation({
        mutationFn: (body: ICreateTradeNameRequest) =>
            rawIngredientService.createRawIngredientTradeName(body),
    });
};

export const useUpdateRawIngredientTradeName = () => {
    return useMutation({
        mutationFn: ({ body, id }: { body: IUpdateTradeNameDto; id: string }) =>
            rawIngredientService.updateRawIngredientTradeName({ body, id }),
    });
};

export const useUploadSafetyDocument = () =>
    useMutation({
        mutationFn: (payload: SafetyDocumentBody) =>
            rawIngredientService.uploadSafetyDocument(payload),
    });

export const useDeleteSafetyDocument = () =>
    useMutation({
        mutationFn: (payload: SafetyDocumentDeleteBody): Promise<void> =>
            rawIngredientService.deleteSafetyDocument(payload),
    });

export const useUploadedSafetyDocuments = (id: number | null) => {
    return useQuery({
        queryKey: [queryKeys.safetyDocuments, id],
        queryFn: () => {
            if (id === null) {
                return Promise.resolve(null);
            }
            return rawIngredientService.getUploadedSafetyDocuments(id);
        },
        enabled: id !== null,
    });
};
