import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { TableStructure } from "common/data";
import editIcon from "assets/images/icons/edit.svg";
import { useLinkWithQuery } from "common/hooks";
import classNames from "classnames";

interface IProps {
    id: number;
    to?: string;
    children?: ReactNode;
    className?: string;
}

const link = `${TableStructure["subIngredients"].link}/`;

export const IconLink: FC<IProps> = ({
    id,
    to = link,
    children = <img src={editIcon} alt="Edit icon" />,
    className = "",
}) => {
    const linkWithQuery = useLinkWithQuery({ baseUrl: to, id });

    return (
        <div className={classNames("flex", className)}>
            <Link to={linkWithQuery} className="colors-secondary-info">
                {children}
            </Link>
        </div>
    );
};
